import React from "react"
import { Card, CardGroup, Col, Image, Row } from "react-bootstrap"

export default function Premier() {
  return (
    <CardGroup fluid="true">
      <Card>
        <Card.Img variant="top" src="/images/mesh-city-wifi.jpg" />
        <Card.Body>
          <Card.Title>Welcome to the Future.</Card.Title>
          <Card.Text>
            We operate low latency, satellite-backhauled Wi-Fi mesh networks
            made up of countless outdoor, ruggedized access points for maximum
            coverage, bandwidth and reliability.
            <br />
            <br />
            <b>No dish or modem needed.</b>
          </Card.Text>
        </Card.Body>
        <Card.Footer>
          <small className="text-muted">Serving select locations.</small>
        </Card.Footer>
      </Card>
      <Card>
        <Card.Img variant="top" src="/images/iphone_wifi_network.png" />
        <Card.Body>
          <Card.Title>Connect Fast to Fast Internet.</Card.Title>
          <Card.Text>
            <li>Create or restart your membership.</li>
            <li>
              Select the <b>boditelwifi</b> hotspot.
            </li>
            <li>Enter your hotspot username and password.</li>
            <li>
              Begin using your favorite apps on the fastest internet including
              Wi-Fi calling with{" "}
              <a
                href="https://www.whatsapp.com"
                target="_blank"
                rel="noopener 
                                noreferrer"
              >
                WhatsApp
              </a>
              ,{" "}
              <a
                href="https://duo.google.com/about/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Duo
              </a>
              ,{" "}
              <a
                href="https://www.skype.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                Skype
              </a>{" "}
              and more.
            </li>
          </Card.Text>
        </Card.Body>
        <Card.Footer>
          <small className="text-muted">
            Membership is only $24.99 per month.
          </small>
        </Card.Footer>
      </Card>
      <Card>
        <Card.Img variant="top" src="/images/tv-feet-kicked-up.jpg" />
        <Card.Body>
          <Card.Title>Stream Your Favorites</Card.Title>
          <Card.Text>
            Immediately available for streaming:
            <br />
            <br />
          </Card.Text>
          <Row>
            <Col>
              <p>
                <a
                  href="https://www.netflix.com"
                  target="_blank"
                  rel="noopener 
                                        noreferrer"
                >
                  <Image
                    src="/images/netflix-logo.png"
                    width="95"
                    height="30"
                    alt="Netflix Logo"
                    fluid
                  />
                </a>
              </p>
            </Col>
            <Col>
              <p>
                <a
                  href="https://www.hulu.com"
                  target="_blank"
                  rel="noopener 
                                        noreferrer"
                >
                  <Image
                    src="/images/hulu-logo.png"
                    width="92"
                    height="30"
                    alt="Hulu Logo"
                    fluid
                  />
                </a>
              </p>
            </Col>
            <Col>
              <p>
                <a
                  href="https://www.youtube.com"
                  target="_blank"
                  rel="noopener 
                                        noreferrer"
                >
                  <Image
                    src="/images/youtube-logo.png"
                    width="114"
                    height="30"
                    alt="YouTube Logo"
                    fluid
                  />
                </a>
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <p>
                <a
                  href="https://amazon.com/primevideo/"
                  target="_blank"
                  rel="noopener 
                                        noreferrer"
                >
                  <Image
                    src="/images/amazon-prime-video-logo.png"
                    width="95"
                    height="30"
                    alt="Amazon Prime Logo"
                    fluid
                  />
                </a>
              </p>
            </Col>
            <Col>
              <p>
                <a
                  href="https://www.sling.com"
                  target="_blank"
                  rel="noopener 
                                        noreferrer"
                >
                  <Image
                    src="/images/sling-tv-logo.png"
                    width="95"
                    height="51"
                    alt="Sling TV Logo"
                    fluid
                  />
                </a>
              </p>
            </Col>
            <Col>
              <p>
                <a
                  href="https://www.tiktok.com"
                  target="_blank"
                  rel="noopener 
                                        noreferrer"
                >
                  <Image
                    src="/images/tiktok-logo.png"
                    width="95"
                    height="51"
                    alt="TikTok Logo"
                    fluid
                  />
                </a>
              </p>
            </Col>
          </Row>
        </Card.Body>
        <Card.Footer>
          <small className="text-muted">
            SD streaming requires 3 Mbps; 5 Mbps for HD
          </small>
        </Card.Footer>
      </Card>
    </CardGroup>
  )
}

import React, { useEffect } from "react"
import { Container } from "react-bootstrap"
import { Hub } from "aws-amplify"
import { AmplifySignOut } from "@aws-amplify/ui-react"

export default function SignOut(props) {
  useEffect(() => {
    document.title = "Sign Out"
  }, [])

  Hub.listen("auth", data => {
    // console.log(data)
    switch (data.payload.event) {
      case "signOut":
        props.history.push("/")
        break
      case "configured":
        console.log("the auth module is configured")
        break
      default:
        break
    }
  })

  return (
    <Container>
      <AmplifySignOut slot="sign-out" />
    </Container>
  )
}

// This functional component asks a user to enter their new password and click the Send Confirmation
// button. Cognito sends them a verification code. User receives the code via email and then enters
// the code along with the new password. Component then confirms that their password has been changed.

import React, { useEffect, useState } from "react"
import {
  Alert,
  Button,
  Container,
  Form,
  FormGroup,
  FormControl,
  FormLabel,
  FormText,
} from "react-bootstrap"
import LoaderButton from "../../components/ui/LoaderButton"
import {
  passwordPolicy,
  hasNumber,
  hasSpecialCharacter,
  hasLowerCase,
  hasUpperCase,
} from "../../libs/validationLib"
// import { AuthContext, useAuthContext } from "../../libs/contextLib"
import { useFormFields } from "../../libs/hooksLib"
import { onError } from "../../libs/errorLib"
import Auth from "@aws-amplify/auth"
import { useHistory } from "react-router-dom"
import "./SignUp.css"

export default function SignUp(props) {
  // GETTER/SETTERS

  // History
  const history = useHistory()

  // User
  const [newUser, setNewUser] = useState(null)
  // const { isAuthenticated, setAuth } = useAuthContext()

  // Alert
  const [showAlert, setShowAlert] = useState(false)
  const [alertHeader, setAlertHeader] = useState("")
  const [alertBody, setAlertBody] = useState("")

  // Fields/Code
  const [fields, handleFieldChange] = useFormFields({
    username: "",
    email: "",
    phone_number: "",
    password: "",
    confirmPassword: "",
    confirmationCode: "",
  })
  const [isLoading, setIsLoading] = useState(false)

  // STRING CONSTANTS
  const failedToSignUp = "Failed to sign up"

  // Automatically executes on load
  useEffect(() => {
    document.title = "Boditel | Sign Up"
  }, [])

  // FUNCTIONS

  // Pre-submit 'sign up' form validation to enable submission
  function validateSignUpFormPreSubmit() {
    return (
      fields.email.length > 0 &&
      fields.password.length > 0 &&
      fields.confirmPassword.length > 0
    )
  }

  // Pre-submit 'confirm' form validation to enable submission
  function validateConfirmFormPreSubmit() {
    return fields.confirmationCode.length > 0
  }

  // Post-submit form validation to alert the user of issues
  function validateCreateAccountFormPostSubmit() {
    if (fields.password.length < 8)
      throw new Error(
        "The new password must be at least 8 characters in length."
      )
    if (!hasNumber(fields.password))
      throw new Error("The new password must contain at least one number.")
    if (!hasSpecialCharacter(fields.password))
      throw new Error(
        "The new password must contain at least one special character."
      )
    if (!hasLowerCase(fields.password))
      throw new Error(
        "The new password must contain at least one lowercase letter."
      )
    if (!hasUpperCase(fields.password))
      throw new Error(
        "The new password must contain at least one uppercase letter."
      )
    if (fields.password !== fields.confirmPassword)
      throw new Error("The new password and confirm password must match.")
  }

  // Handles submission of the create account form
  async function handleSignUpSubmit(event) {
    event.preventDefault()
    setShowAlert(false)

    // Flag that the sign up is being loaded
    setIsLoading(true)

    try {
      validateCreateAccountFormPostSubmit()

      // Begin the sign up process using the Auth module from Amplify and set the created user
      // to a local variable "newUser"
      const newUser = await Auth.signUp({
        username: fields.email,
        password: fields.password,
      })

      // Flag that the sign up has been loaded
      setIsLoading(false)

      // Set local variable "newUser" to component scope variable
      setNewUser(newUser)
    } catch (error) {
      onError(error)

      setShowAlert(true)
      setAlertHeader(failedToSignUp)
      setAlertBody(error.message)

      // Flag that the sign up is no longer loading due to the exception
      setIsLoading(false)
    }
  }

  // Handles submission of the confirm form
  async function handleConfirmSubmit(event) {
    event.preventDefault()
    setShowAlert(false)

    // Flag that the sign up is being loaded
    setIsLoading(true)

    try {
      validateCreateAccountFormPostSubmit()

      // Confirm the sign up process via email and confirm code using the Auth module from Amplify
      // else throw an exception
      await Auth.confirmSignUp(fields.email, fields.confirmationCode)

      // If this line is reached then the sign up was successful so sign in the user
      await Auth.signIn(fields.email, fields.password)

      // Flag that the user has been authenticated
      // setAuth(true)

      // Redirect the authenticated user to the profile page
      history.push("/profile")
    } catch (error) {
      onError(error)

      setShowAlert(true)
      setAlertHeader(failedToSignUp)
      setAlertBody(error.message)

      // Flag that the sign up is no longer loading due to the exception
      setIsLoading(false)
    }
  }

  // Renders a sign up form
  // Calls handleSignUpSubmit() upon submission
  function renderSignUpForm() {
    return (
      <Form onSubmit={handleSignUpSubmit}>
        <h3>Create a new account</h3>
        <FormGroup controlId="email" bsSize="large">
          <FormLabel>Email *</FormLabel>
          <FormControl
            autoFocus
            type="email"
            value={fields.email}
            onChange={handleFieldChange}
            placeholder="Email"
          />
          <FormText className="text-muted">Email = Username</FormText>
        </FormGroup>
        <br />
        <FormGroup controlId="password" bsSize="large">
          <FormLabel>Password *</FormLabel>
          <FormControl
            type="password"
            value={fields.password}
            onChange={handleFieldChange}
            placeholder="Password"
          />
          <FormText className="text-muted">{passwordPolicy}</FormText>
        </FormGroup>
        <br />
        <FormGroup controlId="confirmPassword" bsSize="large">
          <FormLabel>Confirm Password *</FormLabel>
          <FormControl
            type="password"
            onChange={handleFieldChange}
            value={fields.confirmPassword}
            placeholder="Password"
          />
        </FormGroup>
        <br />
        <LoaderButton
          block
          type="submit"
          bsSize="large"
          isLoading={isLoading}
          disabled={!validateSignUpFormPreSubmit()}
        >
          Create Account
        </LoaderButton>
      </Form>
    )
  }

  // Renders a confirmation form
  // Calls handleConfirmSubmit() upon submission
  function renderConfirmationForm() {
    return (
      <Form onSubmit={handleConfirmSubmit}>
        <h3>Confirm your new account</h3>
        <FormGroup controlId="confirmationCode" bsSize="large">
          <FormLabel>Confirmation Code *</FormLabel>
          <FormControl
            autoFocus
            type="tel"
            onChange={handleFieldChange}
            value={fields.confirmationCode}
          />
          <FormText className="text-muted">
            Please check your email for the code.
          </FormText>
        </FormGroup>
        <LoaderButton
          block
          type="submit"
          bsSize="large"
          isLoading={isLoading}
          disabled={!validateConfirmFormPreSubmit()}
        >
          Verify
        </LoaderButton>
      </Form>
    )
  }

  return (
    <Container>
      <div className="alert-div">
        <Alert show={showAlert} variant="danger">
          <Alert.Heading>{alertHeader}</Alert.Heading>
          <p>{alertBody}</p>
          <hr />
          <div className="d-flex justify-content-end">
            <Button
              onClick={() => setShowAlert(false)}
              variant="outline-success"
            >
              Close
            </Button>
          </div>
        </Alert>
      </div>

      <div className="signup-div">
        {newUser === null ? renderSignUpForm() : renderConfirmationForm()}
      </div>
    </Container>
  )
}

import React, { useState, useEffect } from "react"
import { AuthState, onAuthUIStateChange } from "@aws-amplify/ui-components"
import HeaderStyle from "./Header.css"
import MainNavbar from "../layout/navbar/MainNavbar"
import MainNavbarSignedIn from "../layout/navbar/MainNavbarSignedIn"

export default function Header(props) {
  // GETTER/SETTERS

  // User/Authentication
  const [authState, setAuthState] = useState()
  const [user, setUser] = useState()

  // Automatically executes on load
  useEffect(() => {
    return onAuthUIStateChange((nextAuthState, authData) => {
      setAuthState(nextAuthState)
      setUser(authData)
    })
  }, [])

  // If authenticated, return MainNavbarSignedIn; else return MainNavbar
  return authState === AuthState.SignedIn && user ? (
    <MainNavbarSignedIn />
  ) : (
    <MainNavbar />
  )
}

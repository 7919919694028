import React, { useState, useEffect } from "react"
import { Route, BrowserRouter, Switch } from "react-router-dom"
import "./App.css"

// AWS IMPORTS
import { Amplify } from "aws-amplify"
import { AmplifyAuthenticator } from "@aws-amplify/ui-react"
import { AuthState, onAuthUIStateChange } from "@aws-amplify/ui-components"
import awsconfig from "./aws-exports"

// COMPONENT IMPORTS

// Header/Footer
import Header from "./components/layout/Header"
import Footer from "./components/layout/Footer"

// PAGE IMPORTS

// Home
import Home from "./pages/home/Home"

// Corporate
import About from "./pages/corporate/About"
import Privacy from "./pages/corporate/Privacy"
import Terms from "./pages/corporate/Terms"

// Account
import SignUp from "./pages/account/SignUp"
import SignIn from "./pages/account/SignIn"
import SignOut from "./pages/account/SignOut"
import Profile from "./pages/account/Profile"
import ChangeEmail from "./pages/account/ChangeEmail"
import ChangePhone from "./pages/account/ChangePhone"
import ChangePassword from "./pages/account/ChangePassword"
import ResetPassword from "./pages/account/ResetPassword"

Amplify.configure(awsconfig)

export default function App() {
  // GETTER/SETTERS

  // User/Authentication
  const [authState, setAuthState] = useState()
  const [user, setUser] = useState()

  // Automatically executes on load
  useEffect(() => {
    return (
      onAuthUIStateChange((nextAuthState, authData) => {
        setAuthState(nextAuthState)
        setUser(authData)
      }),
      console.log("...")
    )
  }, [])

  return authState === AuthState.SignedIn && user ? (
    // Authenticated
    <AmplifyAuthenticator usernameAlias="email">
      <BrowserRouter>
        <div
          className="App"
          style={{
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            flex: 1,
          }}
        >
          <Header />
          <Switch>
            {/* Always Allowed */}
            <Route exact path="/" component={Home} />
            <Route path="/about" component={About} />
            <Route path="/privacy" component={Privacy} />
            <Route path="/terms" component={Terms} />

            {/* Allowed with authentication */}
            <Route path="/signout" component={SignOut} />
            <Route path="/profile" component={Profile} />
            <Route path="/change-email" component={ChangeEmail} />
            <Route path="/change-password" component={ChangePassword} />
            <Route path="/change-phone" component={ChangePhone} />

            {/* Blocked with reroute to Profile page (disallowed since user is already authenticated)  */}
            <Route path="/signup" component={Profile} />
            <Route path="/signin" component={Profile} />
            <Route path="/reset-password" component={Profile} />
          </Switch>
          <Footer />
        </div>
      </BrowserRouter>
    </AmplifyAuthenticator>
  ) : (
    // Unauthenticated
    <AmplifyAuthenticator usernameAlias="email">
      <BrowserRouter>
        <div
          className="App"
          slot="sign-in"
          style={{
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            flex: 1,
          }}
        >
          <Header />
          <Switch>
            {/* Always Allowed */}
            <Route exact path="/" component={Home} />
            <Route path="/about" component={About} />
            <Route path="/privacy" component={Privacy} />
            <Route path="/terms" component={Terms} />

            {/* Allowed so user can sign up/in to get authenticated */}
            <Route path="/signup" component={SignUp} />
            <Route path="/signin" component={SignIn} />
            <Route path="/reset-password" component={ResetPassword} />

            {/* Blocked with reroute to SignIn page (disallowed since user can only access these with authenticaiton)  */}
            <Route path="/signout" component={SignIn} />
            <Route path="/profile" component={SignIn} />
            <Route path="/change-email" component={SignIn} />
            <Route path="/change-password" component={SignIn} />
            <Route path="/change-phone" component={SignIn} />
          </Switch>

          <Footer />
        </div>
      </BrowserRouter>
    </AmplifyAuthenticator>
  )
}

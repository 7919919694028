import React from "react"
import { Container, Image } from "react-bootstrap"
import { Link } from "react-router-dom"

export default function About() {
  return (
    <div>
      <Container>
        <h1>About Us</h1>
        <Image
          src="/images/happy-students.jpg"
          width="800"
          height="533"
          alt="Boditel User"
          fluid
        />
        <br />
        <br />
        <h2>What is Boditel?</h2>
        <p>
          Technically, Boditel is a discount wireless internet service provider,
          or WISP, but we consider ourselves a champion of broadband internet
          democratization.
          <br />
          <b>
            Our mission is to make broadband internet more accessible to more
            people.
          </b>
        </p>
        <h2>We offer high-speed, low latency broadband internet.</h2>
        <p>
          Boditel is now delivering initial beta service domestically and will
          expand rapidly. During beta, users can expect to see data speeds vary
          from 3Mb/s to 150Mb/s and latency from 20ms to 80ms in most locations
          but expect improvement as we enhance the system. There will also be
          brief periods of no connectivity at all. As we launch more network
          nodes, install more ground stations and improve our networking
          software, data speed, latency and uptime will improve dramatically.
        </p>
        <h2>Supported Devices</h2>
        <p>
          You can use any internet-connected device to connect to the Boditel
          network and begin streaming your favorite shows, movies and music,
          including computers, smart TVs, game consoles, streaming media
          players, set-top boxes, smartphones, and tablets.
          <br />
          <b>
            Need help getting set up? Search our{" "}
            <u>
              <Link to="/support">Help Center</Link>
            </u>{" "}
            for the device you're using.
          </b>
        </p>
        <h2>Plans and Pricing</h2>
        <p>
          We like to keep things as simple and as transparent as possible. Our
          service is only <b>$24.99/mo </b>
          with no extra fees, surcharges or other nonsense like those other
          guys. Per our democratization mission, student and educational
          discounts up to 50% are also available.
          <br />
          <font color="red">
            <b>
              Ask yourself: Is $99+/mo internet service from them really worth
              it?
            </b>
          </font>
        </p>
        {/* <h2>Get Started!</h2>
                    <p>
                        Follow these easy steps to start using Boditel broadband today:<br />
                        1. Create an account by entering your email address below.<br />
                        2. Enter a password.<br />
                        3. Enter a payment method. As a Boditel member, you are charged once a month on the date you signed up.<br />
                        4. Connect to a boditelwifi hotspot.<br />
                        <br />
                        That's it, enjoy!<br />
                        <br />
                        <GetAccess />
                    </p> */}
      </Container>
    </div>
  )
}

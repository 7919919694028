/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:49385562-6ac7-4369-96e4-db17c56e0c31",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_ofgM26ISi",
    "aws_user_pools_web_client_id": "6rvfjgva8vuml0rp4m75b8lpmt",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL",
        "PHONE_NUMBER"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_appsync_graphqlEndpoint": "https://pxpotc3fwne3vakm7vxpjsls3e.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-3fe7qvlh4fhsboj5gh4devxk74",
    "aws_user_files_s3_bucket": "boditelweba18e005727b3442397d01d5ed618e428212902-dev",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;

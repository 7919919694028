// This functional component asks a user to enter their new password and click the Change Password button.
// Cognito sends them a verification code. User receives the code via email and then enters the code.
// Component then confirms that their email has been changed.

import React, { useEffect, useState } from "react"
import {
  Alert,
  Button,
  Container,
  Form,
  FormGroup,
  FormControl,
  FormLabel,
  FormText,
} from "react-bootstrap"
import LoaderButton from "../../components/ui/LoaderButton"
import {
  passwordPolicy,
  hasNumber,
  hasSpecialCharacter,
  hasLowerCase,
  hasUpperCase,
} from "../../libs/validationLib"
import { useFormFields } from "../../libs/hooksLib"
import { onError } from "../../libs/errorLib"
import Auth from "@aws-amplify/auth"
import { useHistory } from "react-router-dom"
import "./ChangePassword.css"

export default function ChangePassword(props) {
  // GETTER/SETTERS

  // History
  const history = useHistory()

  // Alert
  const [showAlert, setShowAlert] = useState(false)
  const [alertHeader, setAlertHeader] = useState("")
  const [alertBody, setAlertBody] = useState("")

  // Fields/Code
  const [fields, handleFieldChange] = useFormFields({
    password: "",
    oldPassword: "",
    confirmPassword: "",
  })
  const [isChanging, setIsChanging] = useState(false)

  // STRING CONSTANTS
  const failedToUpdatePassword = "Failed to update password"

  // Automatically executes on load
  useEffect(() => {
    document.title = "Change Password"
  }, [])

  // FUNCTIONS

  // Pre-submit form validation to enable submission
  function validateFormPreSubmit() {
    return (
      fields.oldPassword.length > 0 &&
      fields.password.length > 0 &&
      fields.confirmPassword.length > 0
    )
  }

  // Post-submit form validation to alert the user of issues
  function validateFormPostSubmit() {
    if (fields.password === fields.oldPassword)
      throw new Error(
        "The new password must be different from the old password."
      )
    if (fields.password.length < 8)
      throw new Error(
        "The new password must be at least 8 characters in length."
      )
    if (!hasNumber(fields.password))
      throw new Error("The new password must contain at least one number.")
    if (!hasSpecialCharacter(fields.password))
      throw new Error(
        "The new password must contain at least one special character."
      )
    if (!hasLowerCase(fields.password))
      throw new Error(
        "The new password must contain at least one lowercase letter."
      )
    if (!hasUpperCase(fields.password))
      throw new Error(
        "The new password must contain at least one uppercase letter."
      )
    if (fields.password !== fields.confirmPassword)
      throw new Error("The new password and confirm password must match.")
  }

  async function handleChangePasswordSubmit(event) {
    event.preventDefault()

    // Flag that the password is being changed
    setIsChanging(true)

    try {
      // Get the current user and update their password using the Auth module from Amplify
      const currentUser = await Auth.currentAuthenticatedUser()

      validateFormPostSubmit()

      // Change the user's password using the Auth module from Amplify
      await Auth.changePassword(
        currentUser,
        fields.oldPassword,
        fields.password
      )

      // Redirect the user to the profile page
      history.push("/profile")
    } catch (error) {
      onError(error)

      setShowAlert(true)
      setAlertHeader(failedToUpdatePassword)
      setAlertBody(error.message)

      // Flag that the password is no longer being changed due to the exception
      setIsChanging(false)
    }
  }

  // Renders a change password form
  // Calls handleChangePasswordSubmit() upon submission
  function renderChangePasswordForm() {
    return (
      <Form onSubmit={handleChangePasswordSubmit}>
        <h3>Change your password</h3>
        <FormGroup size="large" controlId="oldPassword">
          <FormLabel>Old Password</FormLabel>
          <FormControl
            type="password"
            onChange={handleFieldChange}
            value={fields.oldPassword}
            placeholder="Password"
          />
        </FormGroup>
        <hr />
        <FormGroup size="large" controlId="password">
          <FormLabel>New Password</FormLabel>
          <FormControl
            type="password"
            onChange={handleFieldChange}
            value={fields.password}
            placeholder="Password"
          />
          <FormText className="text-muted">{passwordPolicy}</FormText>
        </FormGroup>
        <br />
        <FormGroup size="large" controlId="confirmPassword">
          <FormLabel>Confirm Password</FormLabel>
          <FormControl
            type="password"
            onChange={handleFieldChange}
            value={fields.confirmPassword}
            placeholder="Password"
          />
        </FormGroup>
        <br />
        <LoaderButton
          type="submit"
          size="large"
          disabled={!validateFormPreSubmit()}
          isLoading={isChanging}
        >
          Change Password
        </LoaderButton>
      </Form>
    )
  }

  return (
    <Container>
      <div className="alert-div">
        <Alert show={showAlert} variant="danger">
          <Alert.Heading>{alertHeader}</Alert.Heading>
          <p>{alertBody}</p>
          <hr />
          <div className="d-flex justify-content-end">
            <Button
              onClick={() => setShowAlert(false)}
              variant="outline-success"
            >
              Close
            </Button>
          </div>
        </Alert>
      </div>
      <div className="change-password-div">{renderChangePasswordForm()}</div>
    </Container>
  )
}

import React from "react"
import { Container } from "react-bootstrap"

import Premier from "./Premier"

export default function Home() {
  return (
    <div>
      <Container>
        <Premier />
      </Container>
    </div>
  )
}

import React, { useEffect, useState } from "react"
import { Alert, Button, Container, Col, Row } from "react-bootstrap"
import { Link } from "react-router-dom"
import { onError } from "../../libs/errorLib"
import Auth from "@aws-amplify/auth"
import "./Profile.css"
// import * as Constants from "./pages/Constants"

// GraphQL
// import { listNotes } from "./graphql/queries"
// import {
//   createNote as createNoteMutation,
//   deleteNote as deleteNoteMutation,
// } from "./graphql/mutations"

// const initialFormState = { name: "", description: "" }

export default function Profile(props) {
  // GETTER/SETTERS

  // User
  const [email, setEmail] = useState("")
  const [emailVerified, setEmailVerified] = useState(false)
  const [phoneNumber, setPhoneNumber] = useState("")
  const [phoneNumberVerified, setPhoneNumberVerified] = useState(false)

  // Alert
  const [showAlert, setShowAlert] = useState(false)
  const [alertHeader, setAlertHeader] = useState("")
  const [alertBody, setAlertBody] = useState("")

  // Note
  // const [notes, setNotes] = useState([])
  // const [formData, setFormData] = useState(initialFormState)

  // STRING CONSTANTS
  const profileNotCompleted = "Your profile is incomplete."
  const profileNotCompletedMissingPhone =
    "Please add a phone number to your account for increased security with MFA."
  const profileNotCompletedPhoneNotVerified =
    "Your phone number is not verified."

  // Automatically executes on load
  useEffect(() => {
    document.title = "Boditel | Profile"
    fetchUserAttributes()
    // fetchNotes()
  }, [])

  // FUNCTIONS

  // Sets the user detail (email, phone) from AWS Amplify User
  async function fetchUserAttributes() {
    Auth.currentAuthenticatedUser().then(user => {
      setEmail(user.attributes.email)
      setEmailVerified(user.attributes.email_verified)
      setPhoneNumber(user.attributes.phone_number)
      setPhoneNumberVerified(user.attributes.phone_number_verified)

      // checkProfileCompletion(user.attributes)
    })
  }
  // Checks the user's profile for completion (i.e. phone, billing)
  async function checkProfileCompletion(userAttributes) {
    console.log("Checking profile completion...")
    try {
      // Check if a phone is specified
      // console.log(userAttributes)
      // console.log(userAttributes.email)
      // console.log(userAttributes.email_verified)
      // console.log(userAttributes.phone_number)
      // console.log(userAttributes.phone_number_verified)
      if (
        userAttributes.phone_number === "" ||
        userAttributes.phone_number == null
      )
        throw new Error(profileNotCompletedMissingPhone)
      if (userAttributes.phone_number_verified === false)
        throw new Error(profileNotCompletedPhoneNotVerified)
    } catch (error) {
      onError(error)

      setShowAlert(true)
      setAlertHeader(profileNotCompleted)
      setAlertBody(error.message)
    }
  }

  // Uses the API class to send a query to the GraphQL API and retrieve a list of notes.
  // async function fetchNotes() {
  //   const apiData = await API.graphql({ query: listNotes })
  //   const notesFromAPI = apiData.data.listNotes.items
  //   await Promise.all(
  //     notesFromAPI.map(async note => {
  //       if (note.image) {
  //         const image = await Storage.get(note.image)
  //         note.image = image
  //       }
  //       return note
  //     })
  //   )
  //   setNotes(apiData.data.listNotes.items)
  // }

  // Uses the API class to send a mutation to the GraphQL API and passes in the variables
  // needed for a GraphQL mutation so that we can create a new note with the form data.
  // async function createNote() {
  //   if (!formData.name || !formData.description) return
  //   await API.graphql({
  //     query: createNoteMutation,
  //     variables: { input: formData },
  //   })
  //   if (formData.image) {
  //     const image = await Storage.get(formData.image)
  //     formData.image = image
  //   }
  //   setNotes([...notes, formData])
  //   setFormData(initialFormState)
  // }

  // Sends a GraphQL mutation along with some variables to delete a note.
  // async function deleteNote({ id }) {
  //   const newNotesArray = notes.filter(note => note.id !== id)
  //   setNotes(newNotesArray)
  //   await API.graphql({
  //     query: deleteNoteMutation,
  //     variables: { input: { id } },
  //   })
  // }

  // Handles the image upload
  // async function onChange(e) {
  //   if (!e.target.files[0]) return
  //   const file = e.target.files[0]
  //   setFormData({ ...formData, image: file.name })
  //   await Storage.put(file.name, file)
  //   fetchNotes()
  // }

  return (
    <Container>
      <div className="alert-div">
        <Alert show={showAlert} variant="danger">
          <Alert.Heading>{alertHeader}</Alert.Heading>
          <p>{alertBody}</p>
          <hr />
          <div className="d-flex justify-content-end">
            <Button
              onClick={() => setShowAlert(false)}
              variant="outline-success"
            >
              Close
            </Button>
          </div>
        </Alert>
      </div>

      <div className="profile-div">
        <Row>
          <Col>
            <h2>Account</h2>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col xs lg="4">
            <h4>Membership & Billing</h4>
            <br />
            <Button variant="danger">Cancel Membership</Button>
          </Col>
          <Col>
            <Row>
              <Col>
                <b>Email:</b> {email} (
                {emailVerified ? (
                  <b style={{ color: "green" }}>Verified</b>
                ) : (
                  <b style={{ color: "red" }} to="change-email">
                    Verify Now
                  </b>
                )}
                )
                <br />
                <b>Password:</b> ********
                <br />
                <b>Phone:</b>{" "}
                {phoneNumber !== null && phoneNumber !== "" ? (
                  phoneNumber
                ) : (
                  <Link style={{ color: "red" }} to="change-phone">
                    Add Phone
                  </Link>
                )}
                {phoneNumberVerified ? (
                  <b style={{ color: "green" }}>(Verified)</b>
                ) : (
                  <Link style={{ color: "red" }} to="change-phone">
                    Verify Now
                  </Link>
                )}
                <br />
              </Col>
              <Col>
                <Link to="change-email">Change email</Link>
                <br />
                <Link to="change-password">Change password</Link>
                <br />
                <Link to="change-phone">Change phone</Link>
              </Col>
            </Row>
            <hr />
            <Row>
              <Col>
                •••• •••• •••• ••••
                <br />
                Your next billing date is MM DD, YYYY.
                <br />
              </Col>
              <Col>
                <Link to="#">Manage payment info</Link>
                <br />
                <Link to="#">Billing details</Link>
                <br />
              </Col>
            </Row>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col xs lg="4">
            <h4>Plan Details</h4>
            <br />
          </Col>
          <Col>
            <Row>
              <Col>
                <b>Internet Basic ($24.99/mo)</b>
              </Col>
              <Col>{/* <Link to="#">Change plan</Link> */}</Col>
            </Row>
          </Col>
        </Row>
        {/* <hr />
        <Row>
          <Col xs lg="4">
            <h4>Settings</h4>
            <br />
          </Col>
          <Col>
            <Link to="#">Activate a device</Link>
            <br />
            <Link to="#">Recent device streaming activity</Link>
            <br />
            <Link to="#">Download your personal information</Link>
            <br />
          </Col>
        </Row> */}
      </div>
      {/* <input
            onChange={e => setFormData({ ...formData, 'name': e.target.value})}
            placeholder="Note name"
            value={formData.name}
          />
          <input
            onChange={e => setFormData({ ...formData, 'description': e.target.value})}
            placeholder="Note description"
            value={formData.description}
          />
          <input
            type="file"
            onChange={onChange}
          />
          <button onClick={createNote}>Create Note</button>
          <div style={{marginBottom: 30}}>
            {
              notes.map(note => (
                <div key={note.id || note.name}>
                  <h2>{note.name}</h2>
                  <p>{note.description}</p>
                  <button onClick={() => deleteNote(note)}>Delete note</button>
                  {
                    note.image && <img src={note.image} style={{width: 400}} />
                  }
                </div>
              ))
            }

          </div> */}
    </Container>
  )
}

import React from "react"
import {
  Accordion,
  useAccordionButton,
  Button,
  Card,
  Container,
  ListGroup,
} from "react-bootstrap"
import { Link } from "react-router-dom"

function ContextAwareToggle({ children, eventKey, callback }) {
  const decoratedOnClick = useAccordionButton(
    eventKey,
    () => callback && callback(eventKey)
  )

  return (
    <Button variant="primary" onClick={decoratedOnClick}>
      {children}
    </Button>
  )
}

export default function Terms() {
  return (
    <Container>
      <p>
        Updated: <b>January 1, 2021</b>
      </p>
      <Accordion defaultActiveKey="0">
        <Card>
          <Card.Header>
            <ContextAwareToggle eventKey="0">Introduction</ContextAwareToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="0">
            <Card.Body>
              <p>
                Please read these Terms carefully before using any online
                services (e.g., website, email, or mobile app) provided by
                Boditel LLC (<b>"Boditel", "we", "us", or "our"</b>) that post a
                link to these Terms (the <b>“Online Service”</b>). By visiting
                or otherwise using the Online Service in any manner, you agree
                to the then posted Terms and any applicable Additional Terms
                (defined below), to be bound by them, and that you have read and
                understood them. You also acknowledge, agree and consent to our
                data practices as described in the applicable privacy policy
                posted on the applicable Online Service.
              </p>
              <p>
                These Terms affects your legal rights, responsibilities and
                obligations and govern your use of the Online Service, are
                legally binding, limit Boditel’s liability to you and require
                you to indemnify us and to settle certain disputes through
                individual arbitration.{" "}
                <b>
                  If you do not wish to be bound by these Terms and any
                  Additional Terms, do not use the Online Service and uninstall
                  Online Service’s downloads and applications.
                </b>
              </p>
              <p>
                <b>Additional Terms</b>
                <br />
                In some instances, additional or different terms, posted on the
                Online Service, apply to your use of certain parts of the Online
                Service (individually and collectively <b>"Additional Terms"</b>
                ). To the extent there is a conflict between these Terms and any
                Additional Terms, the Additional Terms will control unless the
                Additional Terms expressly state otherwise.
              </p>
              <p>
                <b>Updates to these Terms and Additional Terms</b>
                <br />
                We may prospectively change these Terms and Additional Terms by
                posting new or changed terms on the Online Service as more fully
                explained in <b>Section 11</b>.
              </p>
              <br />
              <p>
                <b>Quick Links</b>
                <br />
                <b>
                  We have summarized some (but not all) of the main topics of
                  these Terms below. The complete provisions, and not the
                  headings or summaries govern.
                </b>
              </p>
              <br />

              <p>
                <b>Grants and Limitations of Rights</b>
                <ListGroup variant="flush">
                  <ListGroup.Item as="li">
                    We only grant you a limited revocable license to use the
                    Online Service subject to rules and limitations. More at{" "}
                    <b>Section 1</b>.
                  </ListGroup.Item>
                  <ListGroup.Item as="li">
                    You grant us a broad license, which we may sublicense, to
                    the content you submit, including your Profile, which you
                    represent you have the right to allow us to use. You,
                    however, retain ownership of and responsibility for, your
                    content. Use of our Online Service is subject to forum usage
                    rules and we have the right to manage our Online Service to
                    keep its content appropriate More at <b>Section 2</b>.
                  </ListGroup.Item>
                  <ListGroup.Item as="li">
                    Your use of our Online Service is subject to various
                    restrictions designed to protect the Online Service and our
                    users. We may change or discontinue our Online Service in
                    whole or in part. More at <b>Section 3</b>.
                  </ListGroup.Item>
                </ListGroup>
              </p>
              <br />
              <p>
                <b>Limitations on Your Remedies</b>
                <ListGroup variant="flush">
                  <ListGroup.Item as="li">
                    We are providing the Online Service to you on an “as-is”
                    basis, without any warranty of any kind, our liability to
                    you in connection with your use of the Online Service is
                    very limited, and other limitations and disclaimers relate
                    to your use of the Online Service. More at <b>Section 9</b>.
                  </ListGroup.Item>
                  <ListGroup.Item as="li">
                    As permitted by applicable law, our liability is greatly
                    limited as more fully explained in this section. More at{" "}
                    <b>Section 10</b>.
                  </ListGroup.Item>
                </ListGroup>
              </p>
              <p>
                <b>Third-Party Services</b>
                <ListGroup variant="flush">
                  <ListGroup.Item as="li">
                    We are not responsible for third parties or their content,
                    advertisement(s), apps or sites. For instance, portions of
                    the Online Service may be integrated into or linked to
                    third-party sites, platforms and apps that we do not
                    control. Similarly, we may make ads and third-party content
                    or services, which we also may not control, available to you
                    on or via our Online Service. Use caution when dealing with
                    third parties. More at <b>Section 1.E</b>.
                  </ListGroup.Item>
                </ListGroup>
              </p>
              <p>
                <b>Infringement and Dispute Resolution</b>
                <ListGroup variant="flush">
                  <ListGroup.Item as="li">
                    Users may not post content they do not own or control, and
                    users may be suspended or terminated if they do so.
                    Copyright owners may give us notice of infringement as
                    detailed in this section, and we will respond in accordance
                    with applicable law. More at <b>Section 5</b>.
                  </ListGroup.Item>
                  <ListGroup.Item as="li">
                    You can also give notice of trademark and other
                    infringements that you think occur on the Service. More at{" "}
                    <b>Section 5</b>.
                  </ListGroup.Item>
                  <ListGroup.Item as="li">
                    As permitted by law, you agree to arbitrate disputes and
                    waive jury trial and class actions. More at <b>Section 8</b>
                    .
                  </ListGroup.Item>
                </ListGroup>
              </p>
              <p>
                <b>Availability of Online Service</b>
                <ListGroup variant="flush">
                  <ListGroup.Item as="li">
                    We may change or discontinue our Online Service, or your
                    right to access it, in whole or in part. More at{" "}
                    <b>Section 3.C</b>.
                  </ListGroup.Item>
                  <ListGroup.Item as="li">
                    Our Online Service are intended for access from and use in
                    the U.S.A. More at <b>Section 12.C</b>.
                  </ListGroup.Item>
                </ListGroup>
              </p>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Card.Header>
            <ContextAwareToggle eventKey="1">
              Full Online Terms of Use
            </ContextAwareToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="1">
            <Card.Body>
              <Accordion defaultActiveKey="0">
                <Card>
                  <Card.Header>
                    <ContextAwareToggle eventKey="0">
                      1. OWNERSHIP AND YOUR RIGHTS TO USE THE SERVICE AND
                      CONTENT.
                    </ContextAwareToggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body>
                      <p>
                        <b>A. Ownership.</b>
                        <br />
                        The Online Service and all of its content (
                        <b>"Content"</b>), including all copyrights, patents,
                        trademarks, service marks, trade names and all other
                        intellectual property rights therein (
                        <b>"Intellectual Property"</b>), are owned or controlled
                        by Boditel, our licensors, and certain other third
                        parties. All right, title, and interest in and to the
                        Content and Intellectual Property available via the
                        Online Service is the property of Boditel, our licensors
                        or certain other third parties, and is protected by U.S.
                        and international copyright, trademark, trade dress,
                        patent and/or other Intellectual Property and unfair
                        competition rights and laws to the fullest extent
                        possible. Boditel owns the copyright in the selection,
                        compilation, assembly, arrangement, and enhancement of
                        the Content on the Online Service.
                      </p>
                      <p>
                        <b>
                          B. Your Rights to Use the Online Service and Content.
                        </b>
                        <br />
                      </p>
                      <p>
                        <b>(i)</b> Your right to use the Online Service and
                        Content is subject to your strict compliance with these
                        Terms and the Additional Terms. Your right to access and
                        use the Online Service and the Intellectual Property
                        shall automatically terminate upon any violations. These
                        rights are non-exclusive, limited, and revocable by us
                        at any time in our sole discretion without advance
                        notice or liability. As your right to access and use the
                        Online Service and the content is personal to you, you
                        may not assign nor transfer your right; any attempt to
                        do so is void. You may, for your personal,
                        non-commercial, lawful use only (collectively, the
                        following are the <b>"Boditel Licensed Elements"</b>):
                      </p>
                      <ListGroup variant="flush">
                        <ListGroup.Item as="li">
                          <b>(1)</b> Display, view, use, and play the Content on
                          a computer, mobile or other internet enabled or
                          permitted device (<b>"Device"</b>) and/or print one
                          copy of the Content (excluding source and object code
                          in raw form or otherwise) as it is displayed to you;
                          <br />
                          <b>(2)</b> Stream the Content using any of the widgets
                          and/or other digital streaming internet video players,
                          if any, provided on the Online Service;
                          <br />
                          <b>(3)</b> Subject to any applicable Additional Terms,
                          if the Online Service includes a “Send to Friend,”
                          social media sharing or similar tool that allows you
                          to initiate and send to one or more of your contacts a
                          communication that includes content, or to post our
                          content to third-party services or your own site or
                          online service, and the tool is operational, use the
                          tool to do so; provided, however, that you do not do
                          so in any manner that violates applicable law or
                          third-party rights or reflects negativity on us, and
                          only send to recipients you have permission to
                          contact;
                          <br />
                          <b>(4)</b> If the Online Service includes a "Download"
                          link next to a piece of content (including, without
                          limitation, an image, an icon, a wallpaper, a music
                          track, a video, a trailer, an RSS feed), you may only
                          download a single copy of such content to a single
                          Device;
                          <br />
                          <b>(5)</b> Download, install and use one copy of any
                          software, including apps, that we make available on or
                          through the Online Service (<b>"Software"</b>) on your
                          Device in machine-executable object code form only and
                          make one additional copy for back-up purposes;
                          provided, however, that you understand and agree that
                          (i) by allowing you to download the Software, Boditel
                          does not transfer title to the Software to you (i.e.,
                          you own the medium on which the Software is recorded,
                          but the Software's owner (which may be Boditel and/or
                          its third-party Software licensor) will retain full
                          and complete title to such Software); (ii) you may not
                          copy, modify, adapt, translate into any language,
                          distribute, or create derivative works based on the
                          Software, except as expressly authorized in these
                          Terms or applicable Additional Terms, without the
                          prior written consent of Boditel; (iii) you may not
                          assign, rent, lease, or lend the Software to any
                          person or entity and any attempt by you to sublicense,
                          transfer, or assign the Software will be void and of
                          no effect; and (iv) you may not decompile,
                          disassemble, reverse engineer, or attempt to
                          reconstruct, identify, or discover any source code,
                          underlying ideas, underlying user interface
                          techniques, or algorithms of the Software by any means
                          whatsoever, except to the extent the foregoing
                          restriction is prohibited by applicable law;
                          <br />
                          <b>(6)</b> If made available to you, obtain a
                          registered personal account (and/or related username
                          and password) on the Online Service and interact with
                          the Online Service in connection therewith;
                          <br />
                          <b>(7)</b> Link to the Online Service from a website
                          or other online service, so long as: (a) the links
                          only incorporate text, and do not use any Boditel
                          names, logos, or images, (b) the links and the content
                          on your website do not suggest any affiliation with
                          Boditel or cause any other confusion, and (c) the
                          links and the content on your website do not portray
                          Boditel or its products or services in a false,
                          misleading, derogatory, or otherwise offensive manner,
                          and do not contain content that is unlawful,
                          offensive, obscene, lewd, lascivious, filthy, violent,
                          threatening, harassing, or abusive, or that violate
                          any right of any third-party or are otherwise
                          objectionable to Boditel. Boditel reserves the right
                          to suspend or prohibit linking to the Online Service
                          for any reason, in its sole discretion, without
                          advance notice or any liability of any kind to you or
                          any third-party; and
                          <br />
                          <b>(8)</b> Use any other functionality expressly
                          provided by Boditel on or through the Online Service
                          for use by users, subject to these Terms (including,
                          without limitation, functionality to create and/or
                          post User-Generated Content (as defined below)) and
                          any applicable Additional Terms.
                        </ListGroup.Item>
                      </ListGroup>
                      <br />
                      <p>
                        <b>C. Rights of Others.</b>
                        <br />
                        In using the Online Service, you must respect the
                        Intellectual Property and rights of others and Boditel.
                        Your unauthorized use of Content may violate the rights
                        of others and applicable laws and may result in your
                        civil and criminal liability. If you believe that your
                        work has been infringed via the Online Service, see{" "}
                        <b>Section 5</b> below.
                      </p>
                      <p>
                        <b>
                          D. Reservation of all Rights Not Granted as to Content
                          and Online Service.
                        </b>
                        These Terms and any applicable Additional Terms include
                        only narrow, limited grants of rights to use and access
                        the Online Service and content. No right or license may
                        be construed, under any legal theory, by implication,
                        estoppel, industry custom, or otherwise. ALL RIGHTS NOT
                        EXPRESSLY GRANTED TO YOU ARE RESERVED BY BODITEL AND ITS
                        LICENSORS AND OTHER THIRD PARTIES.{" "}
                        <i>
                          Any unauthorized use of any Content or the Online
                          Service for any purpose is prohibited.
                        </i>
                      </p>
                      <p>
                        <b>E. Third-Party Services.</b>
                        We are not responsible for third parties or their
                        content, advertisement(s), apps or sites{" "}
                        <b>"Third-Party Services"</b>). For instance, portions
                        of the Online Service may be integrated with, or linked
                        to, third-party sites, platforms, applications, or other
                        services or materials that we do not control. Similarly,
                        we may make ads and third-party content or services,
                        which we also may not control, available to you on or
                        via our Online Service. This may include the ability to
                        register or sign in to our Online Service using Facebook
                        Connect or other third-party tools, and to post content
                        on third-party sites and services using their plug-ins
                        made available on our Online Service. Use caution when
                        dealing with third parties and consult their terms of
                        use and privacy policies. We take no responsibility for
                        Third-Party Services. If you are accessing or using the
                        Online Service through Apple, Android, or any other
                        platform, these are Third-Party Services. If you access
                        our apps via Apple,{" "}
                        <b>please see the specific Apple terms below</b> that
                        are applicable to you and are incorporated into the
                        Terms by this reference.
                      </p>
                      <p>
                        <b>Terms Applicable For Apple iOS</b>
                      </p>

                      <p>
                        <b>(i)</b> To the extent that you are accessing the
                        Online Service through an Apple mobile application, you
                        acknowledge that these Terms are entered into between
                        you and Boditel and, that Apple, Inc. (<b>“Apple”</b>)
                        is not a party to these Terms other than as third-party
                        beneficiary as contemplated below.
                      </p>

                      <p>
                        <b>(ii)</b> The license granted to you by Boditel under
                        the Terms is subject to the permitted Usage Rules set
                        forth in the App Store Terms of Use (see:{" "}
                        <u>
                          <Link
                            to={{
                              pathname:
                                "http://www.apple.com/legal/itunes/us/terms.html",
                            }}
                            target="_blank"
                          >
                            http://www.apple.com/legal/itunes/us/terms.html
                          </Link>
                        </u>
                        ) and any third-party terms of agreement applicable to
                        the Service.
                      </p>

                      <p>
                        <b>(iii)</b> You acknowledge that Boditel, and not
                        Apple, is responsible for providing the Online Service
                        and Content thereof.
                      </p>

                      <p>
                        <b>(iv)</b> You acknowledge that Apple has no obligation
                        whatsoever to furnish any maintenance or any support
                        services to you with respect to the Online Service.
                      </p>

                      <p>
                        <b>(v)</b> To the maximum extent not prohibited by
                        applicable law, Apple will have no other warranty
                        obligation whatsoever with respect to the Online
                        Service.
                      </p>

                      <p>
                        <b>(vi)</b> Notwithstanding anything to the contrary
                        herein, and subject to the terms and conditions of the
                        Terms, you acknowledge that, solely as between Apple and
                        Boditel, Boditel, and not Apple is responsible for
                        addressing any claims you may have relating to the
                        Online Service, or your possession and/or use thereof,
                        including, but not limited, to: (i) product liability
                        claims; (ii) any claim that the Online Service fails to
                        conform to any applicable legal or regulatory
                        requirement; and (iii) claims arising under consumer
                        protection or similar legislation.
                      </p>

                      <p>
                        <b>(vii)</b> Further, you agree that if the Online
                        Service, or your possession and use of the Online
                        Service, infringes on a third-party’s Intellectual
                        Property rights, you will not hold Apple responsible for
                        the investigation, defense, settlement and discharge of
                        any such Intellectual Property infringement claims.
                      </p>

                      <p>
                        <b>(viii)</b> You acknowledge and agree that Apple, and
                        Apple’s subsidiaries, are third-party beneficiaries of
                        the Terms, and that, upon your acceptance of the terms
                        and conditions of the Terms, Apple will have the right
                        (and will be deemed to have accepted the right) to
                        enforce the Terms against you as a third-party
                        beneficiary thereof.
                      </p>

                      <p>
                        <b>(ix)</b> When using the Online Service, you agree to
                        comply with any and all third-party terms that are
                        applicable to any platform, website, technology or
                        service that interacts with the Online Service.
                      </p>

                      <p>
                        <b>(x)</b> Your use of real time route guidance on the
                        Online Service (if any) is at your sole risk. Location
                        data may not be accurate.
                      </p>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Card.Header>
                    <ContextAwareToggle eventKey="1">
                      2. CONTENT YOU SUBMIT AND FORUM USAGE RULES.
                    </ContextAwareToggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="1">
                    <Card.Body>
                      <p>
                        <b>A. User-Generated Content.</b>
                        <br />
                      </p>
                      <p>
                        <b>(i) General.</b> Boditel may now, or in the future,
                        offer users of the Online Service the opportunity to
                        create, build, post, upload, display, publish,
                        distribute, transmit, broadcast, or otherwise make
                        available on or submit through the Online Service, or on
                        or in response to our pages or posts on any third-party
                        platforms or in connection with any of our promotions by
                        any media or manner, or otherwise submit to us (e.g., on
                        our Facebook or other social media pages, in response to
                        our tweets, through a sweepstakes or contest, or by
                        otherwise sending it to us) (collectively,{" "}
                        <b>"submit"</b>) messages, text, illustrations, files,
                        images, graphics, photos, comments, responses, sounds,
                        music, videos, information, content, ratings, reviews,
                        data, questions, suggestions, personally identifiable
                        information, or other information or materials and the
                        ideas contained therein (collectively, but excluding
                        Boditel Licensed Elements included therein,{" "}
                        <b>“User-Generated Content"</b> or <b>"UGC"</b>). You
                        may submit UGC through your profile, forums, blogs,
                        message boards, social networking environments, content
                        creation and posting tools, gameplay, social
                        communities, contact us tools, email, and other
                        communications functionality. Except to the extent of
                        the rights and license you grant in these Terms and,
                        subject to any applicable Additional Terms, you retain
                        whatever legally cognizable right, title, and interest
                        that you have in your UGC.
                      </p>
                      <p>
                        <b>
                          (ii) Non-Confidentiality of Your User-Generated
                          Content.
                        </b>{" "}
                        Except as otherwise described in the Online Service’s
                        posted{" "}
                        <u>
                          <Link to={{ pathname: "/privacy" }}>
                            Online Privacy Policy
                          </Link>
                        </u>
                        , or any applicable Additional Terms, you agree that (a)
                        your UGC will be treated as non-confidential and
                        non-proprietary by us – regardless of whether you mark
                        them "confidential," "proprietary," or the like – and
                        will not be returned, and (b) to the maximum extent not
                        prohibited by applicable law, Boditel does not assume
                        any obligation of any kind to you or any third-party
                        with respect to your UGC. Upon request, you will provide
                        documentation necessary to authenticate rights to such
                        content and verify your compliance with these Terms or
                        any applicable Additional Terms. You acknowledge that
                        the Internet and mobile communications may be insecure
                        and subject to breaches of security; according, you
                        acknowledge and agree that your UGC is submitted at your
                        own risk.
                      </p>

                      <p>
                        In your communications with Boditel, please keep in mind
                        that we do not seek any unsolicited ideas or materials
                        for products or services, or even suggested improvements
                        to products or services, including, without limitation,
                        ideas, concepts, inventions, or designs for music,
                        websites, apps, books, scripts, screenplays, motion
                        pictures, television shows, theatrical productions,
                        software or otherwise (collectively,
                        <b>"Unsolicited Ideas and Materials"</b>). Any
                        Unsolicited Ideas and Materials you submit are deemed
                        UGC and licensed to us as set forth below. In addition,
                        Boditel retains all of the rights held by members of the
                        general public with regard to your Unsolicited Ideas and
                        Materials. Boditel’s receipt of your Unsolicited Ideas
                        and Materials is not an admission by Boditel of their
                        novelty, priority, or originality, and it does not
                        impair Boditel’s right to contest existing or future
                        Intellectual Property rights relating to your
                        Unsolicited Ideas and Materials.
                      </p>

                      <p>
                        <b>(iii) License to Boditel of Your UGC.</b> Except as
                        otherwise described in any applicable Additional Terms
                        (such as a promotion’s official rules), which
                        specifically govern the submission of your UGC, or in an
                        applicable privacy policy or notice, you hereby grant
                        Boditel, the non-exclusive, unrestricted, unconditional,
                        unlimited, worldwide, irrevocable, perpetual, and
                        cost-free right and license to use, copy, record,
                        distribute, reproduce, disclose, sell, re-sell,
                        sublicense (through multiple levels), display, publicly
                        perform, transmit, publish, broadcast, translate, make
                        derivative works of, and otherwise use and exploit in
                        any manner whatsoever, all or any portion of your UGC
                        (and derivative works thereof), for any purpose
                        whatsoever in all formats, on or through any means or
                        medium now known or hereafter developed, and with any
                        technology or devices now known or hereafter developed,
                        and to advertise, market, and promote the same. Without
                        limitation, the granted rights include the right to: (a)
                        configure, host, index, cache, archive, store, digitize,
                        compress, optimize, modify, reformat, edit, adapt,
                        publish in searchable format, and remove such UGC and
                        combine same with other materials, and (b) use any
                        ideas, concepts, know-how, or techniques contained in
                        any UGC for any purposes whatsoever, including
                        developing, producing, and marketing products and/or
                        services. You understand that in exercising such rights
                        metadata, notices and content may be removed or altered,
                        including copyright management information, and you
                        consent thereto and represent and warrant you have all
                        necessary authority to do so. In order to further effect
                        the rights and license that you grant to Boditel to your
                        UGC, you also, as permitted by applicable law, hereby
                        grant to Boditel, and agree to grant to Boditel, the
                        unconditional, perpetual, irrevocable right to use and
                        exploit your name, persona, and likeness in connection
                        with any UGC, without any obligation or remuneration to
                        you. Except as prohibited by law, you hereby waive, and
                        you agree to waive, any moral rights (including
                        attribution and integrity) that you may have in any UGC,
                        even if it is altered or changed in a manner not
                        agreeable to you. To the extent not waivable, you
                        irrevocably agree not to exercise such rights (if any)
                        in a manner that interferes with any exercise of the
                        granted rights. You understand that you will not receive
                        any fees, sums, consideration, or remuneration for any
                        of the rights granted in this Section 2.A(iii).
                      </p>

                      <p>
                        <b>
                          (iv) Boditel’s Exclusive Right to Manage Our Service.
                        </b>{" "}
                        Boditel may, but will not have any obligation to,
                        review, monitor, display, post, store, maintain, accept,
                        or otherwise make use of, any of your UGC, and Boditel
                        may, in its sole discretion, reject, delete, move,
                        re-format, remove or refuse to post or otherwise make
                        use of UGC without notice or any liability to you or any
                        third-party in connection with our operation of UGC
                        venues in an appropriate manner, such as to enhance
                        accessibility of UGC, address copyright infringement and
                        protect users from harmful UGC. Without limitation, we
                        may, but do not commit to, do so to address content that
                        comes to our attention that we believe is offensive,
                        obscene, lewd, lascivious, filthy, violent, harassing,
                        threatening, abusive, illegal or otherwise objectionable
                        or inappropriate, or to enforce the rights of third
                        parties or these Terms or any applicable Additional
                        Terms. Such UGC submitted by you or others need not be
                        maintained on the Online Service by us for any period of
                        time, and you will not have the right, once submitted,
                        to access, archive, maintain, change, remove, or
                        otherwise use such UGC on the Online Service or
                        elsewhere, except that California minors have certain
                        rights to have certain content about them that they have
                        themselves posted on the Online Service prospectively
                        removed from public display as provided for in the
                        applicable privacy policy or notice.
                      </p>

                      <p>
                        <b>
                          (v) Representations and Warranties Related to Your
                          UGC.
                        </b>{" "}
                        Each time you submit any UGC, you represent and warrant
                        that you are at least the age of majority in the
                        jurisdiction in which you reside and are the parent or
                        legal guardian, or have all proper consents from the
                        parent or legal guardian, of any minor who is depicted
                        in or contributed to any UGC you submit, and that, as to
                        that UGC, (a) you are the sole author and owner of the
                        Intellectual Property and other rights to the UGC, or
                        you have a lawful right to submit the UGC and grant
                        Boditel the rights to it that you are granting by these
                        Terms and any applicable Additional Terms, all without
                        any Boditel obligation to obtain consent of any
                        third-party and without creating any obligation or
                        liability of Boditel; (b) the UGC is accurate; (c) the
                        UGC does not and, as to Boditel’s permitted uses and
                        exploitation set forth in these Terms, will not infringe
                        any Intellectual Property or other right of any
                        third-party; and (d) the UGC will not violate these
                        Terms or any applicable Additional Terms, or cause
                        injury or harm to any person.
                      </p>

                      <p>
                        <b>(vi) Enforcement.</b> Boditel has no obligation to
                        monitor or enforce your Intellectual Property rights to
                        your UGC, but you grant us the right to protect and
                        enforce our rights to your UGC, including initiating
                        actions in your name and on your behalf (at Boditel’s
                        cost and expense, to which you hereby consent and
                        irrevocably appoint Boditel as your attorney-in-fact,
                        with the power of substitution and delegation, which
                        appointment is coupled with an interest).
                      </p>
                      <p>
                        <b>B. Forum Usage Rules.</b> As a user of the Online
                        Service, these Forum Usage Rules (<b>“Rules”</b>) are
                        here to help you understand the conduct that is expected
                        of members of the Online Service’s online forums (
                        <b>"Forums"</b>).
                      </p>
                      <p>
                        <b>(i) Nature of Rules.</b> Your participation in the
                        Forums is subject to all of the Terms, including these
                        Rules:
                      </p>
                      <ListGroup variant="flush">
                        <ListGroup.Item as="li">
                          <b>Your UGC.</b> All of your UGC either must be
                          original with you or you must have all necessary
                          rights in it from third parties in order to permit you
                          to comply with these Terms and any applicable
                          Additional Terms. Your UGC should not contain any
                          visible logos, phrases, or trademarks that belong to
                          third parties. Do not use any UGC that belongs to
                          other people and pass it off as your own; this
                          includes any content that you might have found
                          elsewhere on the Internet. If anyone contributes to
                          your UGC or has any rights to your UGC, or if anyone
                          appears or is referred to in the UGC, then you must
                          also have their permission to submit such UGC to
                          Boditel. (For example, if someone has taken a picture
                          of you and your friend, and you submit that photo to
                          Boditel as your UGC, then you must obtain your
                          friend’s and the photographer’s permission to do so.)
                          <br />
                          <b>
                            Speaking of Photos: No Pictures, Videos, or Images
                            of Anyone Other Than You and Your Friends and
                            Family.
                          </b>{" "}
                          If you choose to submit photos to the Online Service,
                          link to embedded videos, or include other images of
                          real people, then make sure they are of you or of you
                          and someone you know – and only if you have their
                          express permission to submit it.
                          <br />
                          <b>Act Appropriately.</b> All of your Online Service
                          activities must be venue appropriate, as determined by
                          us. Be respectful of others’ opinions and comments so
                          we can continue to build Forums for everyone to enjoy.
                          If you think your UGC might offend someone or be
                          embarrassing to someone, then chances are it probably
                          will and it doesn’t belong on the Online Service.
                          Cursing, harassing, stalking, insulting comments,
                          personal attacks, gossip, and similar actions are
                          prohibited. Your UGC must not threaten, abuse, or harm
                          others, and it must not include any negative comments
                          that are connected to race, national origin, gender,
                          sexual orientation, religion, or handicap. Your UGC
                          must not be defamatory, slanderous, indecent, obscene,
                          pornographic, or sexually explicit. Your UGC must not
                          exploit children under the age of 18. <br />
                          <b>
                            Do Not Use for Commercial or Political Purposes.
                          </b>{" "}
                          Your UGC must not advertise or promote a product or
                          service or other commercial activity, or a politician,
                          public servant, or law.
                          <br />
                          <b>Do Not Use for Inappropriate Purposes.</b> Your UGC
                          must not promote any infringing, illegal, or other
                          similarly inappropriate activity.
                          <br />
                          <b>
                            Be Honest and Do Not Misrepresent Yourself or Your
                            UGC.
                          </b>{" "}
                          Do not impersonate any other person, user, or company,
                          and do not submit UGC that you believe may be false,
                          fraudulent, deceptive, inaccurate, or misleading, or
                          that misrepresents your identity or affiliation with a
                          person or company. In the event you receive anything
                          in consideration from us with respect to your UGC
                          (e.g., coupons, sweepstakes entries, etc.) you
                          represent you will include disclosure of the receipt
                          of this consideration clearly and conspicuously as
                          part of the UGC and include any other disclosures we
                          may require.
                          <br />
                          <b>Others Can See.</b> We hope that you will use the
                          Forums to exchange information and content and have
                          venue appropriate discussions with other members.
                          However, please remember that the Forums are public or
                          semi-public and UGC that you submit on the Online
                          Service within a Forum may be accessible and viewable
                          by other users. Do not submit personally identifying
                          information (e.g., first and last name together,
                          password, phone number, address, credit card number,
                          medical information, email address, or other
                          personally identifiable information or contact
                          information) on Forum spaces and take care when
                          otherwise disclosing this type of information to
                          others.
                          <br />
                          <b>
                            Don't Share Other People's Personal Information.
                          </b>{" "}
                          Your UGC should not reveal another person’s address,
                          phone number, email address, social security number,
                          credit card number, medical information, financial
                          information, or any other information that may be used
                          to track, contact, or impersonate that individual,
                          unless, and in the form and by the method,
                          specifically requested by Boditel (e.g., an email
                          address to send an email invite to a friend).
                          <br />
                          <b>
                            Don’t Damage the Online Service or Anyone’s
                            Computers or Other Devices.
                          </b>{" "}
                          Your UGC must not submit viruses, Trojan horses,
                          spyware, or any other technologies or malicious code
                          that could impact the operation of the Online Service
                          or any computer or other Device.
                          <br />
                          <br />
                          <p>
                            If you submit UGC that Boditel reasonably believes
                            violates these Rules, then we may take any legally
                            available action that we deem appropriate, in our
                            sole discretion. However, we are not obligated to
                            take any action not required by applicable law. We
                            may require, at any time, proof of the permissions
                            referred to above in a form acceptable to us.
                            Failure to provide such proof may lead to, among
                            other things, the UGC in question being removed from
                            the Online Service.
                          </p>
                        </ListGroup.Item>
                      </ListGroup>
                      <br />
                      <p>
                        <b>
                          (ii) Your Interactions With Other Users; Disputes.
                        </b>{" "}
                        You are solely responsible for your interaction with
                        other users of the Online Service, whether online or
                        offline. We are not responsible or liable for the
                        conduct or content of any user. We reserve the right,
                        but have no obligation, to monitor or become involved in
                        disputes between you and other users. Exercise common
                        sense and your best judgment in your interactions with
                        others (e.g., when you submit any personal or other
                        information) and in all of your other online activities.
                      </p>
                      <p>
                        <b>
                          C. Appropriate Content and Alerting Us of Violations.
                        </b>{" "}
                        We expect UGC to be appropriate for a general audience,
                        but do not undertake to monitor it, and you consent to
                        potentially encountering content you find offensive or
                        inappropriate. We may include venue and content rules as
                        Additional Terms. If you discover any content that
                        violates these Terms or any applicable Additional Terms,
                        then you may report it by sending an email to{" "}
                        <u>
                          <a href={`mailto:privacy@boditel.com`}>
                            privacy@boditel.com
                          </a>
                        </u>
                        . For alleged infringements of Intellectual Property
                        rights, see <b>Section 5</b>.
                      </p>
                      <br />
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Card.Header>
                    <ContextAwareToggle eventKey="2">
                      3. ONLINE SERVICE AND CONTENT USE RESTRICTIONS.
                    </ContextAwareToggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="2">
                    <Card.Body>
                      <p>
                        <b>A. Online Service Use Restrictions.</b> You agree
                        that you will not: (i) use the Online Service for any
                        political or commercial purpose in competition with us
                        (including, without limitation, for purposes of
                        advertising, soliciting funds, collecting product
                        prices, and selling products); (ii) use any meta tags or
                        any other “hidden text” utilizing any Intellectual
                        Property; (iii) engage in any activities through or in
                        connection with the Online Service that seek to attempt
                        to or do harm any individuals or entities or are
                        unlawful, offensive, obscene, lewd, lascivious, filthy,
                        violent, threatening, harassing, or abusive, or that
                        violate any right of any third-party, or are otherwise
                        objectionable to Boditel; (iv) decompile, disassemble,
                        reverse engineer, or attempt to reconstruct, identify,
                        or discover any source code, underlying ideas,
                        underlying user interface techniques, or algorithms of
                        the Online Service by any means whatsoever or modify any
                        Online Service source or object code or any Software or
                        other products, services, or processes accessible
                        through any portion of the Online Service; (v) engage in
                        any activity that interferes with a user’s access to the
                        Online Service or the proper operation of the Online
                        Service, or otherwise causes harm to the Online Service,
                        Boditel, or other users of the Online Service; (vi)
                        interfere with or circumvent any security feature
                        (including any digital rights management mechanism,
                        device or other content protection or access control
                        measure) of the Online Service or any feature that
                        restricts or enforces limitations on use of or access to
                        the Online Service, the Content, or the UGC; (vii)
                        harvest or otherwise collect or store any information
                        (including personally identifiable information about
                        other users of the Online Service, including email
                        addresses, without the express consent of such users);
                        (viii) attempt to gain unauthorized access to the Online
                        Service, other computer systems or networks connected to
                        the Online Service, through password mining or any other
                        means; or (ix) otherwise violate these Terms or any
                        applicable Additional Terms.
                      </p>

                      <p>
                        <b>B. Content Use Restrictions.</b> You also agree that,
                        in using the Online Service, you:
                      </p>

                      <p>
                        (i) will not monitor, gather, copy, or distribute the
                        Content (except as may be a result of standard search
                        engine activity or use of a standard browser) on the
                        Online Service by using any robot, rover, “bot”, spider,
                        scraper, crawler, spyware, engine, device, software,
                        extraction tool, or any other automatic device, utility,
                        or manual process of any kind; (ii) will not frame or
                        utilize framing techniques to enclose any such content
                        (including any images, text, or page layout); (iii) will
                        keep intact all Trademark, copyright, and other
                        Intellectual Property and other notices contained in
                        such content; (iv) will not use such content in a manner
                        that suggests an unauthorized association with any of
                        our or our licensors’ products, services, or brands; (v)
                        will not make any modifications to such content (other
                        than to the extent of your specifically permitted use of
                        Boditel Licensed Elements, if applicable); (vi) will not
                        copy, modify, reproduce, archive, sell, lease, rent,
                        exchange, create derivative works from, publish by hard
                        copy or electronic means, publicly perform, display,
                        disseminate, distribute, broadcast, retransmit,
                        circulate or transfer to any third-party or on any
                        third-party application or website, or otherwise use or
                        exploit such content in any way for any purpose except
                        as specifically permitted by these Terms or any
                        applicable Additional Terms or with the prior written
                        consent of an officer of Boditel or, in the case of
                        content from a licensor, the owner of the content; and
                        (vii) will not insert any code or product to manipulate
                        such content in any way that adversely affects any user
                        experience or the Online Service.
                      </p>

                      <p>
                        <b>C. Availability of Online Service and Content.</b>{" "}
                        Boditel, in its sole discretion without advance notice
                        or liability, may immediately suspend or terminate the
                        availability of the Online Service and/or Content (and
                        any elements and features of them), in whole or in part,
                        for any reason, in Boditel’s sole discretion, and
                        without advance notice or liability.
                      </p>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Card.Header>
                    <ContextAwareToggle eventKey="3">
                      4. CREATING AN ACCOUNT.
                    </ContextAwareToggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="3">
                    <Card.Body>
                      <p>
                        <b>A. Accounts.</b> In order to access or use some of
                        the features on the Online Service, you must first
                        register through our online registration process on our
                        website or through one of our mobile apps. The Online
                        Service’s practices governing any resulting collection
                        use, disclosure and management of your personal
                        information are disclosed in the applicable privacy
                        policies or notices. If you are under the age of
                        majority in your state, then you are not permitted to
                        register as a user, create a Profile Page or otherwise
                        use the Online Service or submit personal information to
                        us.
                      </p>
                      <p>
                        If you register for any feature that requires a password
                        and/or username, then you will select your own password
                        at the time of registration (or we may provide you a
                        randomly generated initial password) and you agree that:
                        (i) you will not use a username (or email address) that
                        is already being used by someone else, may impersonate
                        another person, belongs to another person, violates the
                        intellectual property or other right of any person or
                        entity, or is offensive. We may reject the use of any
                        password, username, or email address for any other
                        reason in our sole discretion; (ii) you will provide
                        true, accurate, current, and complete registration
                        information about yourself in connection with the
                        registration process and, as permitted, to maintain and
                        update it including on your Profile Page, continuously
                        and promptly to keep it accurate, current, and complete;
                        (iii) you are solely responsible for all activities that
                        occur under your account, password, and username –
                        whether or not you authorized the activity; (iv) you are
                        solely responsible for maintaining the confidentiality
                        of your password and for restricting access to your
                        Device so that others may not access any password
                        protected portion of the Online Service using your name,
                        username, or password; (v) you will immediately notify
                        us of any unauthorized use of your account, password, or
                        username, or any other breach of security, but accept
                        that we are not responsible for thereafter stopping or
                        preventing unauthorized use; and (vi) you will not sell,
                        transfer, or assign your account or any account rights.
                      </p>
                      <p>
                        We will not be liable for any loss or damage (of any
                        kind and under any legal theory) to you or any third
                        party arising from your inability or failure for any
                        reason to comply with any of the foregoing obligations.
                      </p>

                      <p>
                        If any information that you provide, or if we have
                        reasonable grounds to suspect that any information that
                        you provide, is false, inaccurate, outdated, incomplete,
                        or violates these Terms, any applicable Additional
                        Terms, or any applicable law, then we may suspend or
                        terminate your account. We also reserve the more general
                        and broad right, as permitted by applicable law, to
                        terminate your account or suspend or otherwise deny your
                        access to it or its benefits – all in our sole
                        discretion, for any reason, and without advance notice
                        or liability.
                      </p>

                      <p>
                        <b>B. Profiles.</b> We may from time-to-time permit you
                        to set up private, semi-private and/or public profile
                        pages (<b>"Profile Page(s)"</b>) that allows you to
                        provide and/or display information about you. Your
                        Profile Page may not include any form of prohibited UGC,
                        as outlined in <b>Section 2</b> above. Without limiting
                        the foregoing, Profile Pages may not include content
                        that you are attempting to sell through the Online
                        Service, and cannot be used to conduct commercial
                        activities in competition with us, including, but not
                        limited to, transactions, advertising, fundraising,
                        contests or other promotions absent our prior written
                        consent. We may offer you the ability to set preferences
                        relating to your profile or Online Service’s activities,
                        but settings may not become effective immediately or be
                        error free, and options may change from time-to-time. We
                        assume no responsibility or liability for users' profile
                        material.
                      </p>

                      <p>
                        Profile Pages may only be set up by an authorized
                        representative of the individual that is the subject of
                        the Profile Page. We do not review Profile Pages to
                        determine if they were created by an appropriate party,
                        and we are not responsible for any unauthorized Profile
                        Pages that may appear on the Online Service. If there is
                        any dispute as to whether a Profile Page has been
                        created or is being maintained by an authorized
                        representative of the individual who is the subject of
                        that Profile Page, then we shall have the sole right,
                        but are not obligated, to resolve such dispute as we
                        determine is appropriate in our sole discretion. Such
                        resolution may include, without limitation, deleting or
                        disabling access to Profile Pages, or any portion
                        thereof, at any time without notice.
                      </p>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Card.Header>
                    <ContextAwareToggle eventKey="4">
                      5. PROCEDURE FOR ALLEGING COPYRIGHT INFRINGEMENT.
                    </ContextAwareToggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="4">
                    <Card.Body>
                      <p>
                        If you are a copyright owner who would like to send us a
                        notice pursuant to the Digital Millennium Copyright Act
                        (<b>"DMCA"</b>) to identify content or material posted
                        on the Online Service that is infringing that you would
                        like removed from our Online Service, you may submit
                        such notice to us by following the{" "}
                        <u>
                          <Link
                            to={{
                              pathname: "/dcma",
                            }}
                          >
                            Boditel DMCA instructions
                          </Link>
                        </u>
                        .
                      </p>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Card.Header>
                    <ContextAwareToggle eventKey="5">
                      6. NOTICES, QUESTIONS AND CUSTOMER SERVICE.
                    </ContextAwareToggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="5">
                    <Card.Body>
                      <p>
                        You agree that we may give you notices or otherwise
                        respond to you by mail or to your email (if we have it
                        on file) or in any other manner reasonably elected by
                        us. All legal notices to us must be sent to: Address. If
                        you have a question regarding the Online Service, you
                        may{" "}
                        <u>
                          <Link
                            to={{
                              pathname: "/support",
                            }}
                          >
                            contact Boditel Customer Support
                          </Link>
                        </u>{" "}
                        or call us at 1-800-555-5555. You acknowledge that
                        customer service personnel cannot change or waive Terms
                        or applicable Additional Terms.
                      </p>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Card.Header>
                    <ContextAwareToggle eventKey="6">
                      7. PRODUCT SPECIFICATIONS; PRICING; TYPOGRAPHICAL ERRORS.
                    </ContextAwareToggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="6">
                    <Card.Body>
                      <p>
                        We strive to accurately describe our products or
                        services offered on the Online Service; however, we do
                        not warrant that such specifications, pricing, or other
                        content on the Online Service is complete, accurate,
                        reliable, current, or error-free. As permitted by
                        applicable law, Boditel shall have the right to refuse
                        or cancel any applicable orders in its sole discretion.
                        Your potential orders are offers to purchase subject to
                        our acceptance, which we may reject or cancel subject to
                        refund. Additional Terms may apply.
                      </p>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Card.Header>
                    <ContextAwareToggle eventKey="7">
                      8. DISPUTE RESOLUTION; MANDATORY BINDING ARBITRATION;
                      CLASS ACTION; CLASS ARBITRATION AND COLLECTIVE ARBITRATION
                      WAIVERS.
                    </ContextAwareToggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="7">
                    <Card.Body>
                      <p>
                        <b>
                          IF YOU FOLLOW THE PROCEDURES SET FORTH IN SECTION 8(b)
                          BELOW, YOU HAVE THE RIGHT TO OPT OUT OF THIS DISPUTE
                          RESOLUTION PROVISION (EXCEPT JURY TRIAL WAIVER) WITHIN
                          30 DAYS OF THE EFFECTIVE DATE OF THIS AGREEMENT;
                          PROVIDED HOWEVER IF YOU BECOME A NEW BODITEL CUSTOMER
                          AFTER THE EFFECTIVE DATE OF THIS AGREEMENT, YOU HAVE
                          THE RIGHT TO OPT OUT OF THIS DISPUTE RESOLUTION
                          PROVISION (EXCEPT JURY TRIAL WAIVER) WITHIN 30 DAYS OF
                          YOUR ACCEPTANCE OF THIS AGREEMENT AS DEFINED IN
                          SECTION 1(d) ABOVE (THE "OPT-OUT PERIOD"). OTHERWISE,
                          YOU WILL BE BOUND TO SETTLE ANY DISPUTES YOU MAY HAVE
                          WITH BODITEL THROUGH THE FOLLOWING DISPUTE RESOLUTION
                          PROCEDURES.
                        </b>
                      </p>

                      <p>
                        <b>
                          A. YOU AND BODITEL AGREE TO ARBITRATE — RATHER THAN
                          LITIGATE IN COURT
                        </b>{" "}
                        — any and all claims, disputes, or controversies between
                        you and Boditel, including any parents, subsidiaries,
                        affiliates, officers, directors, employees, or agents of
                        Boditel, whether based in contract, statute, regulation,
                        ordinance, tort (including, but not limited to, fraud,
                        misrepresentation, fraudulent inducement, negligence, or
                        any other intentional tort) or other legal or equitable
                        theory (“Dispute”) that arise out of or in any way
                        relate to this Agreement, any of the Services provided
                        under this Agreement or any other Services or products
                        that Boditel provides to you in connection with this
                        Agreement (including but not limited to amounts that
                        Boditel charges you for Services or products provided,
                        any alleged breach related to the collection, retention
                        or disclosure of your personal information, and any
                        alleged violation of our Privacy Notice). The term
                        “Dispute” means any and all past, present or future
                        disputes, claims or controversies between you and
                        Boditel, whether based in contract, statute, regulation,
                        ordinance, tort (including, without limitation, fraud,
                        misrepresentation, fraudulent inducement, negligence or
                        any other intentional tort) or any other legal or
                        equitable theory, and includes, without limitation, any
                        such disputes, claims or controversies arising out of or
                        relating to the validity, enforceability, interpretation
                        and/or scope of this Dispute Resolution Section,
                        provided, however, that the term “Dispute” does not
                        include any such disputes, claims or controversies
                        arising out of or relating to the validity,
                        enforceability, interpretation and/or scope of the Class
                        Action, Class Arbitration and Collective Arbitration
                        Waivers set forth in Section 8.c. below, or whether
                        claims to resolve any Disputes must proceed solely on an
                        individual basis, all of which shall be decided by a
                        Court of competent jurisdiction, and not by an
                        arbitrator. With that sole exception which shall be
                        broadly construed, the term “Dispute” shall be given the
                        broadest possible meaning. You and Boditel also agree to
                        arbitrate any and all Disputes that arise out of or
                        relate in any way to any services or products provided
                        to you by Boditel or any of its affiliated entities
                        under any other agreement. Notwithstanding this
                        agreement to arbitrate, you and Boditel may bring
                        appropriate Disputes against each other in small claims
                        court, if the Dispute falls within the small claims
                        court's jurisdiction, or before the Federal
                        Communications Commission, the relevant state public
                        utilities commission, or any other federal, state, or
                        local government agency authorized by law to hear the
                        Dispute.
                      </p>

                      <p>
                        <b>B. Opt Out.</b> You may opt out of this dispute
                        resolution provision (except for the jury trial waiver
                        contained in Section 8.g below) by notifying Boditel of
                        that intent during the Opt-Out Period by sending an
                        email to Boditel at ArbitrationOptOut@boditel.com or a
                        letter via U.S. mail to Boditel Legal Department, Attn:
                        Litigation Counsel, 6205B Peachtree Dunwoody Road,
                        Atlanta, GA 30328 stating that you are opting out of
                        this dispute resolution provision. Exercising this
                        right, should you choose to do so, will not affect any
                        of the other terms of this Agreement with Boditel, and
                        you may remain a Boditel customer. If you opt out of the
                        dispute resolution provision, that opt out will remain
                        in effect if Boditel modifies this section in the future
                        or you agree to a new term of service under this
                        Agreement. If you enter into a new agreement with
                        Boditel that includes a dispute resolution provision and
                        you want to opt out of that provision, you will need to
                        follow the instructions in that agreement for opting
                        out.
                      </p>

                      <p>
                        <b>
                          C. "Class Action Waiver" "Class Action Arbitration"
                          and "Collective Arbitration Waiver".
                        </b>{" "}
                        You and Boditel agree that all Disputes between you and
                        Boditel will be arbitrated individually, and that there
                        will be no class, collective, coordinated,
                        representative, or consolidated actions in arbitration
                        (a "Class or Collective Arbitration"). You and Boditel
                        agree that any action or agreement by you to bring
                        claims or to participate in any claims related to a
                        Dispute in a Class or Collective Arbitration is contrary
                        to this Agreement. A Collective Arbitration shall
                        include, but is not limited to, any claim (i) to resolve
                        a Dispute involving two (2) or more similar claims for
                        arbitration filed by or on behalf of one or more
                        claimants; (ii) which involves common questions of law
                        or fact; (iii) which involves an agreement to cooperate
                        or coordinate the arbitration demands being asserted
                        against the same defendant(s); and (iv) is initiated at
                        or near the same time. You and Boditel agree that this
                        class action waiver and Collective Arbitration waiver
                        shall be binding in any arbitral proceeding. No
                        arbitrator shall have the authority to consolidate,
                        join, or allow the coordination of more than one (1)
                        person's claims or to preside over a class, collective,
                        mass or representative proceeding in any form. No
                        arbitrator shall have the authority to determine the
                        enforceability of this Section 8.c. If you or Boditel
                        brings a claim in small claims court, the class action
                        waiver will apply, and neither of us can bring a claim
                        on a class or representative basis. Furthermore, neither
                        you nor Boditel may participate in a class or
                        representative action as a class member if the class
                        action asserts Disputes that would fall within the scope
                        of this arbitration agreement if they were directly
                        asserted by you or Boditel. We both agree that this
                        class action waiver is an essential part of our
                        arbitration agreement and that if this class action
                        waiver is found to be unenforceable by any court then
                        the entire arbitration agreement set forth in this
                        Section 8 will not apply to any Dispute between you and
                        Boditel, except for the provisions of Section 8.g
                        waiving the right to jury trial. This class action and
                        collective arbitration waiver may not be severed from
                        our arbitration agreement.
                      </p>

                      <p>
                        <b>D. Arbitrator Authority.</b> The arbitration between
                        you and Boditel will be binding. In arbitration, there
                        is no judge and no jury. Instead, our disputes will be
                        resolved by an arbitrator, whose authority is governed
                        by the terms of this Agreement. You and Boditel agree
                        that an arbitrator may only award such relief as a court
                        of competent jurisdiction could award, limited to the
                        same extent as a court would limit relief pursuant to
                        the terms of this Agreement. An arbitrator may award
                        attorneys' fees and costs if a court would be authorized
                        to do so, and may issue injunctive or declaratory relief
                        if that relief is required or authorized by the
                        Applicable Law, but that injunctive or declaratory
                        relief may not extend beyond you and your dealings with
                        Boditel. Review of arbitration decisions in the courts
                        is very limited.
                      </p>

                      <p>
                        <b>E. Informal Dispute Resolution.</b> You and Boditel
                        agree that you will try to resolve disputes informally
                        before resorting to arbitration. If you have a dispute,
                        first call Boditel Customer Care at the number listed on
                        your monthly bill statement. If the Boditel
                        representative is unable to resolve your dispute in a
                        timely manner, you agree to then notify Boditel of the
                        dispute by sending a written description of your claim
                        to Boditel Customer Care, ATTN: Corporate Escalation
                        Team, 6205B Peachtree Dunwoody Road, Atlanta, GA 30328
                        so that Boditel can attempt to resolve it with you. If
                        Boditel does not satisfactorily resolve your claim
                        within 30 calendar days of receiving written notice to
                        Boditel Customer Care of your claim, then you may pursue
                        the claim in arbitration. Neither you nor Boditel may
                        initiate arbitration without first following the
                        informal dispute resolution procedure provided in this
                        paragraph and thereafter, if the dispute is still not
                        resolved, the party who desires to initiate arbitration
                        must provide the other written notice of the intent to
                        file for arbitration. If you are sending a written
                        notice of your intent to file for arbitration to
                        Boditel, please send such notice via U.S. mail to
                        Boditel Legal Department, Attn: Litigation Counsel,
                        6205B Peachtree Dunwoody Road, Atlanta, GA 30328. If
                        Boditel is sending you a written notice of our intent to
                        file for arbitration, we will send it to the last known
                        address of record we have on file for you.
                      </p>

                      <p>
                        <b>F. Arbitration Procedures.</b> You and Boditel agree
                        that this Agreement and the services Boditel provides to
                        you affects interstate commerce and that the Federal
                        Arbitration Act and not state arbitration laws applies
                        for all Disputes. All arbitrations shall be conducted by
                        the American Arbitration Association ("AAA"). The AAA's
                        rules are available on its website at www.adr.org or by
                        calling 1-800-778-7879. If the claim asserted in
                        arbitration is for less than $75,000, the AAA's Consumer
                        Arbitration Rules will apply. If the claim asserted is
                        for $75,000 or more, the Commercial Arbitration Rules
                        will apply. If there is a conflict between the AAA's
                        rules and this dispute resolution agreement, this
                        dispute resolution agreement shall control. To initiate
                        arbitration, you must send a letter requesting
                        arbitration and describing your claims to Boditel via
                        U.S. mail to Boditel Legal Department, Attn: Address.
                        You must also comply with the AAA's rules regarding
                        initiation of arbitration. Boditel will pay all filing
                        fees and costs for commencement of arbitration, but you
                        will be responsible for your own attorneys' fees and
                        costs unless otherwise determined by the arbitrator
                        pursuant to the terms of this Agreement or Applicable
                        Law. Boditel will not seek to recover its fees and costs
                        from you in the arbitration, even if allowed under the
                        law, unless your claim has been determined to be
                        frivolous. If you are successful in the arbitration,
                        Boditel will pay your reasonable attorney's fees and
                        costs. If you obtain an award from the arbitrator
                        greater than Boditel's last written settlement offer,
                        Boditel will pay you $5,000 in addition to what you have
                        been awarded in the arbitration. The arbitration will be
                        held in the county of the billing address where Boditel
                        provided you service and either party may appear either
                        in person or by telephone.
                      </p>

                      <p>
                        <b>G. Jury Trial Waiver.</b> If for any reason this
                        arbitration agreement is found to be unenforceable, or
                        if you opt out of this dispute resolution agreement, you
                        and Boditel expressly and knowingly WAIVE THE RIGHT TO
                        TRIAL BY JURY. This means that a Judge rather than a
                        Jury will decide disputes between you and Boditel if,
                        for any reason, the arbitration agreement is not
                        enforced.
                      </p>

                      <p>
                        <b>H. Survival.</b> This dispute resolution provision
                        survives the termination of your Services with Boditel.
                        If you bring a claim against Boditel after termination
                        of your Services that is based in whole or in part on
                        events or omissions that occurred while you were a
                        Boditel customer, this dispute resolution provision
                        shall apply.
                      </p>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Card.Header>
                    <ContextAwareToggle eventKey="8">
                      9. DISCLAIMER OF REPRESENTATIONS AND WARRANTIES.
                    </ContextAwareToggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="8">
                    <Card.Body>
                      <p>
                        <b>A.</b> AS PERMITTED BY APPLICABLE LAW, YOUR ACCESS TO
                        AND USE OF THE ONLINE SERVICE IS AT YOUR SOLE RISK AND
                        THE ONLINE SERVICE IS PROVIDED ON AN "AS IS", "AS
                        AVAILABLE", AND "WITH ALL FAULTS" BASIS. To the fullest
                        extent permissible by applicable law, Boditel and their
                        direct and indirect parents, subsidiaries, affiliates,
                        and each of their respective employees, directors,
                        members, managers, shareholders, agents, vendors,
                        licensors, licensees, contractors, customers,
                        successors, and assigns (collectively,{" "}
                        <b>"Boditel Parties"</b>) hereby disclaim and make no
                        representations, warranties, endorsements, or promises,
                        express or implied, in connection with, or otherwise
                        directly or indirectly related to, without limitation,
                        the Online Service, Content, Boditel Licensed Elements,
                        UGC or other Boditel products or services, except as set
                        forth in subsection C, below.
                      </p>

                      <p>
                        <b>
                          B. EXCEPT FOR ANY SPECIFIC WARRANTIES PROVIDED HEREIN,
                          OR IN APPLICABLE ADDITIONAL TERMS, OR AS OTHERWISE
                          REQUIRED BY APPLICABLE LAW, BODITEL PARTIES HEREBY
                          FURTHER DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED,
                          INCLUDING THE WARRANTIES OF MERCHANTABILITY, FITNESS
                          FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT OR
                          MISAPPROPRIATION OF INTELLECTUAL PROPERTY RIGHTS OF
                          THIRD PARTIES, TITLE, CUSTOM, TRADE, QUIET ENJOYMENT,
                          SYSTEM INTEGRATION AND FREEDOM FROM COMPUTER VIRUS.
                        </b>
                      </p>

                      <p>
                        <b>
                          C. NOTWITHSTANDING THE FOREGOING, THIS SECTION DOES
                          NOT EXPAND OR LIMIT (I) ANY EXPRESS, WRITTEN PRODUCT
                          WARRANTY OR RELATED DISCLAIMERS THAT ARE PROVIDED BY
                          BODITEL PARTIES OR THEIR SUPPLIERS WITH REGARD TO A
                          PHYSICAL PRODUCT SOLD BY BODITEL PARTIES TO YOU, OR
                          ANY WARRANTY ON A PHYSICAL PRODUCT TO THE EXTENT
                          REQUIRED BY APPLICABLE LAW; (II) BODITEL PARTIES’
                          LIABILITY FOR PERSONAL INJURY TO YOU CAUSED BY BODITEL
                          PARTIES TO THE EXTENT NOT WAIVABLE OR CANNOT BE
                          LIMITED UNDER APPLICABLE LAW; OR (III) ANY CAUSE OF
                          ACTION YOU MAY HAVE AGAINST BODITEL PARTIES THAT IS
                          NOT WAIVABLE OR CANNOT BE LIMITED UNDER APPLICABLE
                          LAW.
                        </b>
                      </p>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Card.Header>
                    <ContextAwareToggle eventKey="9">
                      10. LIMITATIONS OF OUR LIABILITY.
                    </ContextAwareToggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="9">
                    <Card.Body>
                      <p>
                        <b>
                          A. AS PERMITTED BY APPLICABLE LAW, UNDER NO
                          CIRCUMSTANCES WILL ANY BODITEL PARTIES BE RESPONSIBLE
                          OR LIABLE FOR ANY LOSS OR DAMAGES OF ANY KIND,
                        </b>{" "}
                        including personal injury or death or for any direct,
                        indirect, economic, exemplary, special, punitive,
                        incidental, or consequential losses or damages of any
                        kind, including without limitation loss of profits, in
                        connection with, or otherwise directly or indirectly
                        related to, without limitation, the Online Service,
                        Content, Boditel App Licensed Elements, UGC or other
                        Boditel products or services, except, to the extent not
                        waivable under applicable law, for direct damages for
                        personal injury caused by a physical product
                        manufactured, sold or provided by Boditel.
                      </p>

                      <p>
                        <b>B.</b> The foregoing limitations of liability will
                        apply even if any of the events or circumstances were
                        foreseeable and even if Boditel Parties were advised of
                        or should have known of the possibility of such losses
                        or damages, regardless of whether you bring an action
                        based in contract, negligence, strict liability, or tort
                        (including whether caused, in whole or in part, by
                        negligence, acts of god, telecommunications failure, or
                        destruction of the Online Service).
                      </p>

                      <p>
                        <b>
                          C. AS PERMITTED BY APPLICABLE LAW, IN NO EVENT WILL
                          BODITEL PARTIES’ TOTAL LIABILITY TO YOU, FOR ALL
                          POSSIBLE DAMAGES, LOSSES, AND CAUSES OF ACTION IN
                          CONNECTION WITH YOUR ACCESS TO AND USE OF THE ONLINE
                          SERVICE AND YOUR RIGHTS UNDER THESE TERMS, EXCEED AN
                          AMOUNT EQUAL TO THE AMOUNT YOU HAVE PAID BODITEL IN
                          CONNECTION WITH THE TRANSACTION(S) THAT UNDERLIE THE
                          CLAIM(S).
                        </b>
                      </p>

                      <p>
                        <b>
                          D. NOTWITHSTANDING THE FOREGOING, THIS SECTION DOES
                          NOT EXPAND OR LIMIT (I) ANY EXPRESS, WRITTEN PRODUCT
                          WARRANTY OR RELATED DISCLAIMERS THAT ARE PROVIDED BY
                          BODITEL PARTIES OR THEIR SUPPLIERS WITH REGARD TO A
                          PHYSICAL PRODUCT SOLD BY BODITEL PARTIES TO YOU, OR
                          ANY WARRANTY ON A PHYSICAL PRODUCT TO THE EXTENT
                          REQUIRED BY APPLICABLE LAW; (II) BODITEL PARTIES’
                          LIABILITY FOR PERSONAL INJURY TO YOU CAUSED BY BODITEL
                          PARTIES TO THE EXTENT NOT WAIVABLE OR CANNOT BE
                          LIMITED UNDER APPLICABLE LAW; OR (III) ANY CAUSE OF
                          ACTION YOU MAY HAVE AGAINST BODITEL PARTIES THAT IS
                          NOT WAIVABLE OR CANNOT BE LIMITED UNDER APPLICABLE
                          LAW.
                        </b>
                      </p>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Card.Header>
                    <ContextAwareToggle eventKey="10">
                      11. UPDATES TO TERMS.
                    </ContextAwareToggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="10">
                    <Card.Body>
                      <p>
                        It is your responsibility to review the posted Terms and
                        any applicable Additional Terms each time you use the
                        Online Service (at least prior to each transaction or
                        submission). EACH TIME YOU SIGN IN TO OR OTHERWISE USE
                        THE ONLINE SERVICE YOU ARE ENTERING INTO A NEW AGREEMENT
                        WITH US ON THE THEN APPLICABLE TERMS OF USE AND YOU
                        AGREE THAT WE MAY NOTIFY YOU OF NEW TERMS BY POSTING
                        THEM ON THE ONLINE SERVICE (OR IN ANY OTHER REASONABLE
                        MANNER OF NOTICE WHICH WE ELECT), AND THAT YOUR USE OF
                        THE ONLINE SERVICE AFTER SUCH NOTICE CONSTITUTES YOUR
                        GOING FORWARD AGREEMENT TO THE NEW TERMS FOR YOUR NEW
                        USE AND TRANSACTIONS. Any new Terms or Additional Terms
                        will be effective as to new use and transactions as of
                        the time that we post them, or such later date as may be
                        specified in them or in other notice to you. In the
                        event any notice to you of new, revised, or additional
                        terms is determined by a tribunal to be insufficient,
                        the prior agreement shall continue until sufficient
                        notice to establish a new agreement occurs. You can
                        reject any new, revised or additional terms by
                        discontinuing use of the Online Service.
                      </p>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Card.Header>
                    <ContextAwareToggle eventKey="11">
                      12. GENERAL PROVISIONS.
                    </ContextAwareToggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="11">
                    <Card.Body>
                      <p>
                        <b>A. Boditel’s Consent or Approval.</b> As to any
                        provision in these Terms or any applicable Additional
                        Terms that grants Boditel a right of consent or
                        approval, or permits Boditel to exercise a right in its
                        “sole discretion,” Boditel may exercise that right in
                        its sole and absolute discretion. Note Boditel consent
                        or approval may be deemed to have been granted by
                        Boditel without being in writing and signed by an
                        officer of Boditel.
                      </p>

                      <p>
                        <b>B. Indemnity.</b> As permitted by applicable law, you
                        agree to, and you hereby, defend (if requested by
                        Boditel), indemnify, and hold Boditel Parties harmless
                        from and against any and all claims, damages, losses,
                        costs, investigations, liabilities, judgments, fines,
                        penalties, settlements, interest, and expenses
                        (including attorneys’ fees) that directly or indirectly
                        arise from or are related to any claim, suit, action,
                        demand, or proceeding made or brought against any
                        Boditel Party, or on account of the investigation,
                        defense, or settlement thereof, arising out of or in
                        connection with: (i) your UGC; (ii) your use of the
                        Online Service and your activities in connection with
                        the Online Service; (iii) your breach or alleged breach
                        of these Terms or any applicable Additional Terms; (iv)
                        your violation or alleged violation of any laws, rules,
                        regulations, codes, statutes, ordinances, or orders of
                        any governmental or quasi-governmental authorities in
                        connection with your use of the Online Service or your
                        activities in connection with the Online Service; (v)
                        information or material transmitted through your Device,
                        even if not submitted by you, that infringes, violates,
                        or misappropriates any copyright, trademark, trade
                        secret, trade dress, patent, publicity, privacy, or
                        other right of any person or entity; (vi) any
                        misrepresentation made by you; and (vii) Boditel
                        Parties’ use of the information that you submit to us
                        (including your UGC) subject to our{" "}
                        <u>
                          <Link to={{ pathname: "/privacy" }}>
                            Online Privacy Policy
                          </Link>
                        </u>{" "}
                        (all of the foregoing, "Claims and Losses"). You will
                        cooperate as fully required by Boditel Parties, in the
                        defense of any Claim and Losses. Notwithstanding the
                        foregoing, Boditel Parties retain the exclusive right to
                        settle, compromise, and pay any and all Claims and
                        Losses. Boditel Parties reserve the right to assume the
                        exclusive defense and control of any Claims and Losses.
                        You will not settle any Claims and Losses without, in
                        each instance, the prior written consent of an officer
                        of a Boditel Party. This section is not intended to
                        limit any causes of action against us that you may have
                        but are not waivable under applicable law.
                      </p>

                      <p>
                        <b>
                          C. Operation of Online Service; Availability of
                          Products and Services; International Issues.
                        </b>{" "}
                        Boditel controls and operates the Online Service from
                        the U.S.A., and makes no representation that the Online
                        Service is appropriate or available for use beyond the
                        U.S.A. If you use the Online Service from other
                        locations, you are doing so on your own initiative and
                        responsible for compliance with applicable local laws
                        regarding your online conduct and acceptable content, if
                        and to the extent local laws apply.
                      </p>

                      <p>
                        <b>D. Export Controls.</b> You are responsible for
                        complying with all applicable trade regulations and laws
                        both foreign and domestic. Except as authorized by U.S.
                        law, you agree and warrant not to export or re-export
                        the software to any county, or to any person, entity, or
                        end-user subject to U.S. export controls or sanctions.
                      </p>

                      <p>
                        <b>E. Severability; Interpretation.</b> If any provision
                        of these Terms, or any applicable Additional Terms, is
                        for any reason deemed invalid, unlawful, void, or
                        unenforceable by a court or arbitrator of competent
                        jurisdiction, then that provision will be deemed
                        severable from these Terms or the applicable Additional
                        Terms, and the invalidity of the provision will not
                        affect the validity or enforceability of the remainder
                        of these Terms or the applicable Additional Terms. To
                        the extent permitted by applicable law, you agree to
                        waive and will waive, any applicable statutory and
                        common law that may permit a contract to be construed
                        against its drafter.
                      </p>

                      <p>
                        <b>
                          F. Investigations; Cooperation with Law Enforcement;
                          Termination; Survival.
                        </b>{" "}
                        As permitted by applicable law, Boditel reserves the
                        right, without limitation, to: (i) investigate any
                        suspected breaches of its Online Service’s security or
                        its information technology or other systems or networks,
                        (ii) investigate any suspected breaches of these Terms
                        and any applicable Additional Terms, (iii) use any
                        information obtained by Boditel in accordance with its{" "}
                        <u>
                          <Link to={{ pathname: "/privacy" }}>
                            Online Privacy Policy
                          </Link>
                        </u>{" "}
                        in connection with reviewing law enforcement databases
                        or complying with applicable laws and use and/or
                        disclose any information obtained by Boditel to comply
                        with law enforcement requests or legal requirements in
                        accordance with its{" "}
                        <u>
                          <Link to={{ pathname: "/privacy" }}>
                            Online Privacy Policy
                          </Link>
                        </u>
                        , (iv) involve and cooperate with law enforcement
                        authorities in investigating any of the foregoing
                        matters, (v) prosecute violators of these Terms and any
                        applicable Additional Terms, and (vi) discontinue the
                        Online Service, in whole or in part, or, suspend or
                        terminate your access to it, in whole or in part,
                        including any user accounts or registrations, at any
                        time, without notice, for any reason and without any
                        obligation to you or any third-party. Any suspension or
                        termination will not affect your obligations to Boditel
                        under these Terms or any applicable Additional Terms.
                        Upon suspension or termination of your access to the
                        Online Service, or upon notice from Boditel, all rights
                        granted to you under these Terms or any applicable
                        Additional Terms will cease immediately, and you agree
                        that you will immediately discontinue use of the Online
                        Service. The provisions of these Terms and any
                        applicable Additional Terms, which by their nature
                        should survive your suspension or termination will
                        survive, including the rights and licenses you grant to
                        Boditel in these Terms, as well as the indemnities,
                        releases, disclaimers, and limitations on liability and
                        the provisions regarding jurisdiction, choice of law, no
                        class action, and mandatory arbitration.
                      </p>

                      <p>
                        <b>G. Assignment.</b> Boditel may assign its rights and
                        obligations under these Terms and any applicable
                        Additional Terms, in whole or in part, to any party at
                        any time without any notice. These Terms and any
                        applicable Additional Terms may not be assigned by you,
                        and you may not delegate your duties under them, without
                        the prior written consent of an officer of Boditel.
                      </p>

                      <p>
                        <b>H. Complete Agreement; No Waiver.</b> These Terms,
                        and any applicable Additional Terms, reflect our
                        complete agreement regarding the Online Service and
                        supersede any prior agreements, representations,
                        warranties, assurances or discussion related to the
                        Online Service. Except as expressly set forth in these
                        Terms or any applicable Additional Terms, (i) no failure
                        or delay by you or Boditel in exercising any of rights,
                        powers, or remedies under will operate as a waiver of
                        that or any other right, power, or remedy, and (ii) no
                        waiver or modification of any term of these Terms or any
                        applicable Additional Terms will be effective unless in
                        writing and signed by the party against whom the waiver
                        or modification is sought to be enforced.
                      </p>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </Container>
  )
}
